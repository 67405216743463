.circle[data-v-ab0ef64a] {
  height: 80px;
  width: 80px;
  text-align: center;
  border-radius: 50%;
  display: table;
}
.circle.pic[data-v-ab0ef64a] {
  background-size: 50px 50px;
  display: table-cell;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
}
.circle.green[data-v-ab0ef64a] {
  background: #66C400;
}
.circle.red[data-v-ab0ef64a] {
  background: #D0021B;
}
.circle.wallet[data-v-ab0ef64a] {
  background-image: url(~assets/img/icon-success-wallet@2x.png);
}
.circle.locked[data-v-ab0ef64a] {
  background-image: url(~assets/img/icon-success-locked@2x.png);
}
.circle.calendar[data-v-ab0ef64a] {
  background-image: url(~assets/img/icon-success-calendar@2x.png);
}
.money-header[data-v-ab0ef64a] {
  color: #2B295E;
  font-family: 'Open Sans', sans-serif;
  font-size: 36px;
  margin-bottom: 5px;
  margin-top: 5px;
  font-weight: 300;
}
.payment-text[data-v-ab0ef64a] {
  color: #6a6a6a;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
  margin-top: 5px;
}
.pt10[data-v-ab0ef64a] {
  padding-top: 10px;
}
.mb20[data-v-ab0ef64a] {
  margin-bottom: 20px;
}
