
[data-v-49efcca6] .green_dot {
  background: #8DC63F;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: inline-block;
}
[data-v-49efcca6] .red_dot {
  background: #D9534F;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: inline-block;
}
[data-v-49efcca6] .blue_dot {
  background: #2B295E;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: inline-block;
}
[data-v-49efcca6] .yellow_dot {
  background: #F8C41C;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: inline-block;
}
[data-v-49efcca6] .color-cb {
  color: #70A7DB !important;
}
[data-v-49efcca6] .color-sg {
  color: #6A6A6A !important;
}
[data-v-49efcca6] .color-rf {
  color: #C9338F !important;
}
.parent-box[data-v-49efcca6] {
  display: grid;
  grid-gap: 10px 20px;
  grid-template-columns: minmax(0, 3fr) minmax(0, 1fr);
  width: 100%;
  grid-column-start: auto;
  position: relative;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
.parent-box[data-v-49efcca6] {
    -ms-grid-columns: 3fr 1fr;
    display: -ms-grid;
}
.parent-box div[data-v-49efcca6]:nth-child(5) {
    -ms-grid-column: 2;
    -ms-grid-row: 2;
    margin-left: 20px;
}
}
label.input-label[data-v-49efcca6] {
  float: left;
  width: 100%;
  padding: 10px 0;
  margin: 0 !important;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: bold;
  line-height: 19px;
  color: #2B295E;
}
label.input-label.label-blank[data-v-49efcca6] {
  margin-bottom: 18.67px;
}
