
[data-v-d27bd004] .green_dot {
  background: #8DC63F;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: inline-block;
}
[data-v-d27bd004] .red_dot {
  background: #D9534F;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: inline-block;
}
[data-v-d27bd004] .blue_dot {
  background: #2B295E;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: inline-block;
}
[data-v-d27bd004] .yellow_dot {
  background: #F8C41C;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: inline-block;
}
[data-v-d27bd004] .color-cb {
  color: #70A7DB !important;
}
[data-v-d27bd004] .color-sg {
  color: #6A6A6A !important;
}
[data-v-d27bd004] .color-rf {
  color: #C9338F !important;
}
body[data-v-d27bd004] {
  background: #ddeefc;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
}
*[data-v-d27bd004] {
  box-sizing: border-box;
}
*[data-v-d27bd004]:focus {
  outline: none;
}
.card-form[data-v-d27bd004] {
  padding: 0px 15px 50px 15px;
  max-width: 570px;
  margin: auto;
  width: 100%;
}
@media screen and (max-width: 576px) {
.card-form[data-v-d27bd004] {
    margin: 0 auto;
}
}
.card-form__inner[data-v-d27bd004] {
  background: #fff;
  padding: 35px;
  padding-top: 0px;
}
@media screen and (max-width: 480px) {
.card-form__inner[data-v-d27bd004] {
    padding: 25px;
}
}
@media screen and (max-width: 375px) {
.card-form__inner[data-v-d27bd004] {
    padding: 0px;
}
}
.card-form__row[data-v-d27bd004] {
  display: flex;
  align-items: flex-start;
}
@media screen and (max-width: 480px) {
.card-form__row[data-v-d27bd004] {
    flex-wrap: wrap;
}
}
.card-form__col[data-v-d27bd004] {
  flex: auto;
  margin-right: 20px;
}
.card-form__col[data-v-d27bd004]:last-child {
  margin-right: 0;
}
@media screen and (max-width: 480px) {
.card-form__col[data-v-d27bd004] {
    margin-right: 0;
    flex: unset;
    width: 100%;
    margin-bottom: 20px;
}
.card-form__col[data-v-d27bd004]:last-child {
    margin-bottom: 0;
}
}
.card-form__col.-cvv[data-v-d27bd004] {
  max-width: 150px;
}
@media screen and (max-width: 480px) {
.card-form__col.-cvv[data-v-d27bd004] {
    max-width: initial;
}
}
.card-form__group[data-v-d27bd004] {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
.card-form__group .card-input__input[data-v-d27bd004] {
  flex: 1;
  margin-right: 15px;
}
.card-form__group .card-input__input[data-v-d27bd004]:last-child {
  margin-right: 0;
}
.card-item[data-v-d27bd004] {
  max-width: 430px;
  height: 270px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 2;
  width: 100%;
}
@media screen and (max-width: 480px) {
.card-item[data-v-d27bd004] {
    max-width: 310px;
    height: 220px;
    width: 90%;
    margin-top: 20px;
}
}
@media screen and (max-width: 375px) {
.card-item[data-v-d27bd004] {
    height: 180px;
    width: 100%;
}
}
.card-item.-active .card-item__side.-front[data-v-d27bd004] {
  transform: perspective(1000px) rotateY(180deg) rotateX(0deg) rotateZ(0deg);
}
.card-item.-active .card-item__side.-back[data-v-d27bd004] {
  transform: perspective(1000px) rotateY(0) rotateX(0deg) rotateZ(0deg);
}
.card-item__focus[data-v-d27bd004] {
  position: absolute;
  z-index: 3;
  border-radius: 5px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: all 0.35s cubic-bezier(0.71, 0.03, 0.56, 0.85);
  opacity: 0;
  pointer-events: none;
  overflow: hidden;
  border: 2px solid rgba(255, 255, 255, 0.65);
}
.card-item__focus[data-v-d27bd004]:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: #08142f;
  height: 100%;
  border-radius: 5px;
  filter: blur(25px);
  opacity: 0.5;
}
.card-item__focus.-active[data-v-d27bd004] {
  opacity: 1;
}
.card-item__side[data-v-d27bd004] {
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.3);
  transform: perspective(2000px) rotateY(0deg) rotateX(0deg) rotate(0deg);
  transform-style: preserve-3d;
  transition: all 0.8s cubic-bezier(0.71, 0.03, 0.56, 0.85);
  backface-visibility: hidden;
  height: 100%;
}
.card-item__side.-back[data-v-d27bd004] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transform: perspective(2000px) rotateY(-180deg) rotateX(0deg) rotate(0deg);
  z-index: 2;
  padding: 0;
  height: 100%;
}
.card-item__side.-back .card-item__cover[data-v-d27bd004] {
  transform: rotateY(-180deg);
}
.card-item__bg[data-v-d27bd004] {
  max-width: 100%;
  display: block;
  max-height: 100%;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.card-item__cover[data-v-d27bd004] {
  position: absolute;
  height: 100%;
  background-image: linear-gradient(#2B295E, #BF087F);
  left: 0;
  top: 0;
  width: 100%;
  border-radius: 15px;
  overflow: hidden;
}
.card-item__cover[data-v-d27bd004]:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(6, 2, 29, 0.3);
}
.card-item__top[data-v-d27bd004] {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 40px;
  padding: 0 10px;
}
@media screen and (max-width: 480px) {
.card-item__top[data-v-d27bd004] {
    margin-bottom: 25px;
}
}
@media screen and (max-width: 375px) {
.card-item__top[data-v-d27bd004] {
    margin-bottom: 15px;
}
}
.card-item__chip[data-v-d27bd004] {
  width: 60px;
}
@media screen and (max-width: 480px) {
.card-item__chip[data-v-d27bd004] {
    width: 50px;
}
}
@media screen and (max-width: 375px) {
.card-item__chip[data-v-d27bd004] {
    width: 40px;
}
}
.card-item__type[data-v-d27bd004] {
  height: 45px;
  position: relative;
  display: flex;
  justify-content: flex-end;
  max-width: 100px;
  margin-left: auto;
  width: 100%;
}
@media screen and (max-width: 480px) {
.card-item__type[data-v-d27bd004] {
    height: 40px;
    max-width: 90px;
}
}
@media screen and (max-width: 375px) {
.card-item__type[data-v-d27bd004] {
    height: 30px;
}
}
.card-item__typeImg[data-v-d27bd004] {
  max-width: 100%;
  object-fit: contain;
  max-height: 100%;
  object-position: top right;
}
.card-item__info[data-v-d27bd004] {
  color: #fff;
  width: 100%;
  max-width: calc(100% - 90px);
  padding: 10px 10px;
  font-weight: 500;
  display: block;
  cursor: pointer;
}
@media screen and (max-width: 480px) {
.card-item__info[data-v-d27bd004] {
    padding: 10px;
}
}
.card-item__holder[data-v-d27bd004] {
  opacity: 0.7;
  font-size: 13px;
  margin-bottom: 6px;
}
@media screen and (max-width: 480px) {
.card-item__holder[data-v-d27bd004] {
    font-size: 12px;
    margin-bottom: 5px;
}
}
.card-item__wrapper[data-v-d27bd004] {
  font-family: "Source Code Pro", monospace;
  padding: 25px 15px;
  position: relative;
  z-index: 4;
  height: 100%;
  text-shadow: 7px 6px 10px rgba(14, 42, 90, 0.8);
  user-select: none;
}
@media screen and (max-width: 480px) {
.card-item__wrapper[data-v-d27bd004] {
    padding: 20px 10px;
}
}
.card-item__name[data-v-d27bd004] {
  font-size: 18px;
  line-height: 1;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
}
@media screen and (max-width: 480px) {
.card-item__name[data-v-d27bd004] {
    font-size: 16px;
}
}
.card-item__nameItem[data-v-d27bd004] {
  display: inline-block;
  min-width: 8px;
  position: relative;
}
.card-item__number[data-v-d27bd004] {
  font-weight: 500;
  line-height: 1;
  color: #fff;
  font-size: 27px;
  margin-bottom: 35px;
  display: inline-block;
  padding: 10px 15px;
  cursor: pointer;
}
@media screen and (max-width: 480px) {
.card-item__number[data-v-d27bd004] {
    font-size: 21px;
    margin-bottom: 15px;
    padding: 10px 10px;
}
}
@media screen and (max-width: 375px) {
.card-item__number[data-v-d27bd004] {
    font-size: 19px;
    margin-bottom: 10px;
    padding: 10px 10px;
}
}
.card-item__numberItem[data-v-d27bd004] {
  width: 16px;
  display: inline-block;
}
.card-item__numberItem.-active[data-v-d27bd004] {
  width: 30px;
}
@media screen and (max-width: 480px) {
.card-item__numberItem[data-v-d27bd004] {
    width: 13px;
}
.card-item__numberItem.-active[data-v-d27bd004] {
    width: 16px;
}
}
@media screen and (max-width: 375px) {
.card-item__numberItem[data-v-d27bd004] {
    width: 12px;
}
.card-item__numberItem.-active[data-v-d27bd004] {
    width: 8px;
}
}
.card-item__content[data-v-d27bd004] {
  color: #fff;
  display: flex;
  align-items: flex-start;
}
.card-item__date[data-v-d27bd004] {
  flex-wrap: wrap;
  font-size: 18px;
  margin-left: 0px;
  padding: 10px 0 0 0;
  display: inline-flex;
  width: 80px;
  white-space: nowrap;
  flex-shrink: 0;
  cursor: pointer;
}
@media screen and (max-width: 480px) {
.card-item__date[data-v-d27bd004] {
    font-size: 16px;
}
}
.card-item__dateItem[data-v-d27bd004] {
  position: relative;
}
.card-item__dateItem span[data-v-d27bd004] {
  width: 22px;
  display: inline-block;
}
.card-item__dateTitle[data-v-d27bd004] {
  opacity: 0.7;
  font-size: 13px;
  width: 100%;
  margin-bottom: 3px;
}
@media screen and (max-width: 480px) {
.card-item__dateTitle[data-v-d27bd004] {
    font-size: 12px;
}
}
.card-item__band[data-v-d27bd004] {
  background: rgba(0, 0, 19, 0.8);
  width: 100%;
  height: 50px;
  margin-top: 30px;
  position: relative;
  z-index: 2;
}
@media screen and (max-width: 480px) {
.card-item__band[data-v-d27bd004] {
    margin-top: 20px;
}
}
@media screen and (max-width: 375px) {
.card-item__band[data-v-d27bd004] {
    height: 40px;
    margin-top: 10px;
}
}
.card-item__cvv[data-v-d27bd004] {
  text-align: right;
  position: relative;
  z-index: 2;
  padding: 15px;
}
.card-item__cvv .card-item__type[data-v-d27bd004] {
  opacity: 0.7;
}
@media screen and (max-width: 375px) {
.card-item__cvv[data-v-d27bd004] {
    padding: 10px 15px;
}
}
.card-item__cvvTitle[data-v-d27bd004] {
  padding-right: 10px;
  font-family: "Source Code Pro", monospace;
  font-size: 15px;
  font-weight: 500;
  color: #fff;
  margin-bottom: 5px;
}
.card-item__cvvBand[data-v-d27bd004] {
  font-family: "Source Code Pro", monospace;
  height: 45px;
  background: #fff;
  margin-bottom: 30px;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 10px;
  color: #1a3b5d;
  font-size: 18px;
  border-radius: 4px;
  box-shadow: 0px 10px 20px -7px rgba(32, 56, 117, 0.35);
}
@media screen and (max-width: 480px) {
.card-item__cvvBand[data-v-d27bd004] {
    height: 40px;
    margin-bottom: 20px;
}
}
@media screen and (max-width: 375px) {
.card-item__cvvBand[data-v-d27bd004] {
    margin-bottom: 15px;
}
}
.card-list[data-v-d27bd004] {
  margin-bottom: -130px;
  padding-bottom: 90px;
  padding-left: 35px;
  padding-right: 35px;
}
@media screen and (max-width: 480px) {
.card-list[data-v-d27bd004] {
    margin-bottom: -120px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 120px;
}
}
.card-input-Container[data-v-d27bd004] {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  width: 100%;
}
.card-input-selectContainer[data-v-d27bd004] {
  position: relative;
}
.card-input-selectContainer[data-v-d27bd004]:before {
  content: "";
  display: block;
  height: 8px;
  left: auto;
  margin: auto;
  position: absolute;
  right: 10px;
  bottom: 15px;
  width: 14px;
  pointer-events: none;
  background-image: url(~assets/img/icon-down@2x.png);
  background-size: contain;
  background-repeat: no-repeat;
}
.card-input[data-v-d27bd004] {
  margin-bottom: 20px;
}
.card-input__label[data-v-d27bd004] {
  font-size: 16px;
  font-weight: 600;
  color: #2B295E;
  width: 100%;
  display: block;
  user-select: none;
  line-height: 150%;
  font-family: "Open Sans", sans-serif;
}
.card-input__input[data-v-d27bd004] {
  width: 100%;
  height: 40px;
  border-radius: 3px;
  box-shadow: none;
  border: 1px solid #c7c7c7;
  transition: all 0.3s ease-in-out;
  font-size: 14px;
  font-weight: normal;
  padding: 4px 10px;
  background: none;
  box-sizing: border-box;
  color: #6a6a6a;
  font-family: "Open Sans", sans-serif;
}
.card-input__input[data-v-d27bd004]::placeholder {
  font-style: italic;
  color: #c7c7c7;
}
.card-input__input[data-v-d27bd004]:hover,
.card-input__input[data-v-d27bd004]:focus {
  border-color: #c7c7c7;
}
.card-input__input[data-v-d27bd004]:focus {
  box-shadow: 0px 10px 20px -13px rgba(32, 56, 117, 0.35);
}
.card-input__input.-select[data-v-d27bd004] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  font-style: italic;
  background: rgba(255, 255, 255, 0);
  background: -webkit-linear-gradient(rgba(255, 255, 255, 0), #e6f2ff);
  background: -o-linear-gradient(rgba(255, 255, 255, 0), #e6f2ff);
  background: -moz-linear-gradient(rgba(255, 255, 255, 0), #e6f2ff);
  background: linear-gradient(180deg, #FFFFFF 0%, #F3F3F3 100%);
  -webkit-border-radius: 3px !important;
  -moz-border-radius: 3px !important;
  -ms-border-radius: 3px !important;
  -o-border-radius: 3px !important;
  border-radius: 3px;
  border: 1px solid #C7C7C7;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  height: 40px;
  padding: 4px 35px 4px 10px;
  width: 100%;
  color: #2B295E;
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
  box-sizing: border-box;
}
.slide-fade-up-enter-active[data-v-d27bd004] {
  transition: all 0.25s ease-in-out;
  transition-delay: 0.1s;
  position: relative;
}
.slide-fade-up-leave-active[data-v-d27bd004] {
  transition: all 0.25s ease-in-out;
  position: absolute;
}
.slide-fade-up-enter[data-v-d27bd004] {
  opacity: 0;
  transform: translateY(15px);
  pointer-events: none;
}
.slide-fade-up-leave-to[data-v-d27bd004] {
  opacity: 0;
  transform: translateY(-15px);
  pointer-events: none;
}
.slide-fade-right-enter-active[data-v-d27bd004] {
  transition: all 0.25s ease-in-out;
  transition-delay: 0.1s;
  position: relative;
}
.slide-fade-right-leave-active[data-v-d27bd004] {
  transition: all 0.25s ease-in-out;
  position: absolute;
}
.slide-fade-right-enter[data-v-d27bd004] {
  opacity: 0;
  transform: translateX(10px) rotate(45deg);
  pointer-events: none;
}
.slide-fade-right-leave-to[data-v-d27bd004] {
  opacity: 0;
  transform: translateX(-10px) rotate(45deg);
  pointer-events: none;
}
