
[data-v-012498a2] .green_dot {
  background: #8DC63F;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: inline-block;
}
[data-v-012498a2] .red_dot {
  background: #D9534F;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: inline-block;
}
[data-v-012498a2] .blue_dot {
  background: #2B295E;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: inline-block;
}
[data-v-012498a2] .yellow_dot {
  background: #F8C41C;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: inline-block;
}
[data-v-012498a2] .color-cb {
  color: #70A7DB !important;
}
[data-v-012498a2] .color-sg {
  color: #6A6A6A !important;
}
[data-v-012498a2] .color-rf {
  color: #C9338F !important;
}
[data-v-012498a2] .Notifications--style {
  background: #C9338F !important;
  padding: 10px 20px 10px 20px;
  border: 1px solid #C9338F;
  max-height: 100px;
  box-shadow: 0px 2px 5px 0 rgba(42, 56, 95, 0.5);
  border-radius: 2px;
  color: #ffffff;
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
}
[data-v-012498a2] .Notifications--style:before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f071";
  color: #ffffff;
  position: absolute;
}
[data-v-012498a2] .Notifications--style .notification-content {
  padding-left: 25px;
}
.centered404[data-v-012498a2] {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
}
