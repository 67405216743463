.success[data-v-5cb48af7] {
  height: 35px;
  max-width: 350px;
  width: 100%;
  border: 1px solid #66C400;
  border-radius: 3px;
  background-color: white;
  -webkit-box-shadow: 0 2px 6px 0 rgba(208, 2, 27, 0.3);
  box-shadow: 0 2px 6px 0 rgba(102, 196, 0, 0.4);
  color: #66C400;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  padding: 9px 5px 8px 5px;
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  line-height: 125%;
  z-index: 2 !important;
}
.success p[data-v-5cb48af7] {
  margin: 0px;
  position: absolute;
  width: 100%;
  float: left;
  box-sizing: border-box;
  left: 0px;
}
.success[data-v-5cb48af7]::after {
  height: 11px;
  width: 11px;
  background: white;
  border-bottom: 1px solid #66C400;
  border-right: 1px solid #66C400;
  content: '.';
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 28px;
  left: calc(50% - 5.5px);
  color: transparent;
}
.error[data-v-5cb48af7] {
  height: 35px;
  max-width: 350px;
  width: 100%;
  border: 1px solid red;
  border-radius: 3px;
  background-color: white;
  -webkit-box-shadow: 0 2px 6px 0 rgba(208, 2, 27, 0.3);
  box-shadow: 0 2px 6px 0 rgba(102, 196, 0, 0.4);
  color: red;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  padding: 9px 5px 8px 5px;
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  line-height: 125%;
  z-index: 2 !important;
}
.error p[data-v-5cb48af7] {
  margin: 0px;
  position: absolute;
  width: 100%;
  float: left;
  box-sizing: border-box;
  left: 0px;
}
.error[data-v-5cb48af7]::after {
  height: 11px;
  width: 11px;
  background: white;
  border-bottom: 1px solid red;
  border-right: 1px solid red;
  content: '.';
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 28px;
  left: calc(50% - 5.5px);
  color: transparent;
}
