.parent-box[data-v-bddc8054] {
  display: grid;
  width: 100%;
  grid-gap: 0px;
  grid-template-columns: minmax(0, 1fr);
}
@media (min-width: 768px) {
.parent-box[data-v-bddc8054] {
    grid-gap: 20px;
    grid-template-columns: minmax(0, 3fr) minmax(0, 1fr);
}
}
.div-button[data-v-bddc8054] {
  display: flex;
  align-items: flex-end;
  padding-bottom: 10px;
  padding-top: 10px;
}
.position[data-v-bddc8054] {
  position: relative;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
.parent-box[data-v-bddc8054] {
    -ms-grid-columns: 3fr 1fr;
    display: -ms-grid;
}
.div-button[data-v-bddc8054] {
    margin-left: 20px;
    -ms-grid-column: 2;
}
}
