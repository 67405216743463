
[data-v-81ba70e8] .green_dot {
  background: #8DC63F;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: inline-block;
}
[data-v-81ba70e8] .red_dot {
  background: #D9534F;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: inline-block;
}
[data-v-81ba70e8] .blue_dot {
  background: #2B295E;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: inline-block;
}
[data-v-81ba70e8] .yellow_dot {
  background: #F8C41C;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: inline-block;
}
[data-v-81ba70e8] .color-cb {
  color: #70A7DB !important;
}
[data-v-81ba70e8] .color-sg {
  color: #6A6A6A !important;
}
[data-v-81ba70e8] .color-rf {
  color: #C9338F !important;
}
.Paylink__result__body[data-v-81ba70e8] {
  padding-top: 30px;
}
.Paylink__result__header[data-v-81ba70e8] {
  color: #2B295E;
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  padding-top: 35px;
  font-weight: 600;
}
.Paylink__result__errorText[data-v-81ba70e8] {
  color: #d0021b;
  font-size: 16px;
  font-family: 'Open sans', sans-serif;
  position: relative;
  font-weight: 600;
  padding-top: 20px;
}
.Paylink__result__successText[data-v-81ba70e8] {
  color: #66c400;
  font-size: 16px;
  font-family: 'Open sans', sans-serif;
  position: relative;
  font-weight: 600;
  padding-top: 20px;
}
