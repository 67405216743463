
[data-v-e26101b0] .green_dot {
  background: #8DC63F;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: inline-block;
}
[data-v-e26101b0] .red_dot {
  background: #D9534F;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: inline-block;
}
[data-v-e26101b0] .blue_dot {
  background: #2B295E;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: inline-block;
}
[data-v-e26101b0] .yellow_dot {
  background: #F8C41C;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: inline-block;
}
[data-v-e26101b0] .color-cb {
  color: #70A7DB !important;
}
[data-v-e26101b0] .color-sg {
  color: #6A6A6A !important;
}
[data-v-e26101b0] .color-rf {
  color: #C9338F !important;
}
.card-form[data-v-e26101b0] {
  padding: 0px 15px 50px 15px;
  max-width: 570px;
  margin: auto;
  width: 100%;
}
@media screen and (max-width: 576px) {
.card-form[data-v-e26101b0] {
    margin: 0 auto;
}
}
.card-form__button[data-v-e26101b0] {
  width: 100%;
  height: 55px;
  background: #C9338F;
  border: none;
  border-radius: 3px;
  font-size: 22px;
  font-weight: 500;
  font-family: "Open Sans", sans-serif;
  color: #fff;
  margin: 45px auto 0px auto;
  cursor: pointer;
  position: relative;
  max-width: 160px;
  text-align: center;
  padding: 5px 10px 5px 20px;
  display: block;
}
@media screen and (max-width: 480px) {
.card-form__button[data-v-e26101b0] {
    margin: 40px auto 0px auto;
}
}
.card-form__button[data-v-e26101b0]:before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f058";
  position: absolute;
  left: 15px;
}
.card-form__button-disabled[data-v-e26101b0] {
  opacity: 0.4;
}
