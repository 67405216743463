.vs__input {
  z-index: 0;
  box-sizing: border-box !important;
  width: 100%;
  height: 40px;
  padding: 10px;
  font-family: "Open sans", sans-serif;
  font-size: 14px;
  font-weight: normal;
  color: #2B295E;
  background: transparent;
  border: 1px solid #c7c7c7;
  border-radius: 3px;
}
.vs__input:focus {
  outline: none !important;
}
::-webkit-search-cancel-button {
  position: relative;
  right: 20px;
}
::-webkit-input-placeholder {
  font-style: italic;
  font-weight: normal;
  color: #c7c7c7;
}
::-moz-placeholder {
  font-style: italic;
  font-weight: normal;
  color: #c7c7c7;
}
:-ms-input-placeholder {
  font-style: italic;
  font-weight: normal;
  color: #c7c7c7;
}
:-moz-placeholder {
  font-style: italic;
  font-weight: normal;
  color: #c7c7c7;
}
.vue-suggestion .vs__list {
  width: 100%;
  text-align: left;
  border: none;
  border-top: none;
  max-height: 400px;
  width: 532px;
  overflow-y: auto;
  border-top: 1px solid #c7c7c7;
  border-bottom: 1px solid #c7c7c7;
  position: relative;
  z-index: 999;
}
.vue-suggestion .vs__list .vs__list-item {
  background-color: #fff;
  padding: 10px;
  border-left: 1px solid #c7c7c7;
  border-right: 1px solid #c7c7c7;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  color: #2B295E;
}
.vue-suggestion .vs__list .vs__list-item:last-child {
  border-bottom: none;
}
.vue-suggestion .vs__list .vs__list-item:hover {
  background-color: #eee !important;
}
.vue-suggestion .vs__list,
.vue-suggestion .vs__loading {
  position: absolute;
}
.vue-suggestion .vs__list .vs__list-item {
  cursor: pointer;
}
.vue-suggestion .vs__list .vs__list-item.vs__item-active {
  background-color: #f3f6fa;
}
