
[data-v-1d62f5b7] .green_dot {
  background: #8DC63F;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: inline-block;
}
[data-v-1d62f5b7] .red_dot {
  background: #D9534F;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: inline-block;
}
[data-v-1d62f5b7] .blue_dot {
  background: #2B295E;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: inline-block;
}
[data-v-1d62f5b7] .yellow_dot {
  background: #F8C41C;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: inline-block;
}
[data-v-1d62f5b7] .color-cb {
  color: #70A7DB !important;
}
[data-v-1d62f5b7] .color-sg {
  color: #6A6A6A !important;
}
[data-v-1d62f5b7] .color-rf {
  color: #C9338F !important;
}
.form-group-pg[data-v-1d62f5b7] {
  position: relative;
  margin-bottom: 10px;
}
.form-group-pg.ml-1[data-v-1d62f5b7] {
  margin-left: -1px;
}
.form-group-pg.ml-1 .input[data-v-1d62f5b7] {
  border-left-color: transparent !important;
}
.input[data-v-1d62f5b7] {
  z-index: 0;
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  padding: 10px;
  font-family: "Open sans", sans-serif;
  font-size: 14px;
  font-weight: normal;
  color: #2B295E;
  background: transparent;
  border: 1px solid #c7c7c7;
  border-radius: 3px;
}
.input.bold[data-v-1d62f5b7] {
  font-weight: bold;
}
.input.disabled[data-v-1d62f5b7] {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.5;
}
.input.right[data-v-1d62f5b7] {
  border-radius: 0 3px 3px 0;
}
.input.left[data-v-1d62f5b7] {
  border-radius: 3px 0 0 3px;
}
.input.center[data-v-1d62f5b7] {
  border-radius: 0 0 0 0;
}
.input.error[data-v-1d62f5b7] {
  border-color: #d0021b;
}
.input[data-v-1d62f5b7]:focus {
  outline: none !important;
}
.bigger-padding[data-v-1d62f5b7] {
  padding-right: 35px;
}
label.input-label[data-v-1d62f5b7] {
  float: left;
  width: 100%;
  padding: 10px 0;
  margin: 0 !important;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: bold;
  line-height: 19px;
  color: #2B295E;
}
label.input-label.label-blank[data-v-1d62f5b7] {
  margin-bottom: 18.67px;
}
.iconClipboard[data-v-1d62f5b7] {
  position: absolute;
  bottom: 12px;
  width: 15px;
  cursor: pointer;
  right: 10px;
  display: initial;
}
[data-v-1d62f5b7]::-webkit-input-placeholder {
  font-style: italic;
  font-weight: normal;
  color: #c7c7c7;
}
[data-v-1d62f5b7]::-moz-placeholder {
  font-style: italic;
  font-weight: normal;
  color: #c7c7c7;
}
[data-v-1d62f5b7]:-ms-input-placeholder {
  font-style: italic;
  font-weight: normal;
  color: #c7c7c7;
}
[data-v-1d62f5b7]:-moz-placeholder {
  font-style: italic;
  font-weight: normal;
  color: #c7c7c7;
}
.error-message[data-v-1d62f5b7] {
  position: relative;
  top: -71px;
  left: calc(50% - 50px);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100px;
  height: 35px;
  padding: 9px 5px 8px 5px;
  margin-bottom: -35px;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  line-height: 125%;
  color: white;
  text-align: center;
  background-color: #d0021b;
  border: 1px solid #d0021b;
  border-radius: 3px;
  -webkit-box-shadow: 0 -2px 6px 0 rgba(208, 2, 27, 0.3);
  box-shadow: 0 -2px 6px 0 rgba(208, 2, 27, 0.3);
}
.error-message[data-v-1d62f5b7]::after {
  position: absolute;
  top: 28px;
  left: calc(50% - 5.5px);
  width: 11px;
  height: 11px;
  color: transparent;
  content: ".";
  background: #d0021b;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.hide-icon[data-v-1d62f5b7] {
  display: none;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
.hide-icon[data-v-1d62f5b7] {
    position: absolute;
    bottom: 12px;
    width: 15px;
    cursor: pointer;
    right: 10px;
    display: initial;
}
}
