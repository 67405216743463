
[data-v-78e33756] .green_dot {
  background: #8DC63F;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: inline-block;
}
[data-v-78e33756] .red_dot {
  background: #D9534F;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: inline-block;
}
[data-v-78e33756] .blue_dot {
  background: #2B295E;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: inline-block;
}
[data-v-78e33756] .yellow_dot {
  background: #F8C41C;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: inline-block;
}
[data-v-78e33756] .color-cb {
  color: #70A7DB !important;
}
[data-v-78e33756] .color-sg {
  color: #6A6A6A !important;
}
[data-v-78e33756] .color-rf {
  color: #C9338F !important;
}
/*@light-blue: #70A7DB;
@dark-blue: #2B295E;
@pink: #BF087F;*/
/* TABS */
.box-width[data-v-78e33756] {
  width: 100%;
}
@media (min-width: 992px) {
.box-width[data-v-78e33756] {
    width: 850px;
}
}
[data-v-78e33756] .vue-tabs {
  border-radius: 3px 3px 0px 0px !important;
  width: 850px;
}
[data-v-78e33756] .vue-tabs .nav-tabs-navigation {
  height: 79px;
  padding: 20px 15px 0px 15px;
  overflow: hidden;
}
[data-v-78e33756] .vue-tabs .nav-tabs-navigation .nav-tabs-wrapper {
  overflow: hidden;
  height: 79px;
}
[data-v-78e33756] .vue-tabs .nav-tabs {
  border-bottom: 0px solid #fff !important;
  margin-top: 0px !important;
}
[data-v-78e33756] .vue-tabs .nav-tabs li > a {
  line-height: 1 !important;
  height: 59px;
  box-sizing: border-box;
  margin-right: -1px;
  padding: 20px 30px;
  border-color: rgba(255, 255, 255, 0.3);
  border-radius: 3px 3px 0px 0px;
  border-bottom: 0px solid transparent;
  text-align: center;
}
[data-v-78e33756] .vue-tabs .nav-tabs li > a:hover {
  border-color: rgba(255, 255, 255, 0.3);
}
[data-v-78e33756] .vue-tabs .nav-tabs li > a span {
  color: white;
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: bold;
}
[data-v-78e33756] .vue-tabs .nav-tabs li.active > a,[data-v-78e33756] .vue-tabs .nav-tabs li.active > a:focus,[data-v-78e33756] .vue-tabs .nav-tabs li.active > a:hover {
  border-radius: 3px 3px 0px 0px;
  border: 1px solid white;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.1);
  width: auto;
  background: white;
}
[data-v-78e33756] .vue-tabs .nav-tabs li.active > a span,[data-v-78e33756] .vue-tabs .nav-tabs li.active > a:focus span,[data-v-78e33756] .vue-tabs .nav-tabs li.active > a:hover span {
  color: #2B295E;
  font-family: 'Montserrat';
  font-size: 16px;
}
[data-v-78e33756] .vue-tabs.green {
  background: #66c400;
}
[data-v-78e33756] .vue-tabs.red {
  background: #d0021b;
}
[data-v-78e33756] .vue-tabs.blue {
  background: #70A7DB;
}
[data-v-78e33756] .vue-tabs.yellow {
  background: #f8c41c;
}
[data-v-78e33756] .vue-tabs.waiting .tab:first-child {
  background-color: #f8c41c;
}
[data-v-78e33756] .vue-tabs.waiting .tab:not(:first-child) {
  background-color: #66c400;
}
[data-v-78e33756] .vue-tabs.waiting.blue .tab:not(:first-child) {
  background-color: #70A7DB;
}
[data-v-78e33756] .vue-tabs .nav > li {
  min-width: 126px;
  height: 59px;
}
[data-v-78e33756] .vue-tabs .nav > li a:hover,[data-v-78e33756] .vue-tabs .nav > li a:focus {
  text-decoration: none;
  background-color: transparent;
}
[data-v-78e33756] .tab-content {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.1);
  padding: 15px 20px;
  height: auto;
  min-height: 180px;
  float: left;
  width: 850px;
  box-sizing: border-box;
  background: #ffffff;
  border-top: 0px solid transparent;
}
.caller-section[data-v-78e33756] {
  width: 135px;
  position: absolute;
  top: 27px;
  left: 740px;
  float: right;
  text-align: center;
  color: #ffffff;
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 500;
}
.caller-section p[data-v-78e33756] {
  margin: 5px 0px 5px 0px;
}
.pin-caller-section[data-v-78e33756] {
  width: 135px;
  position: absolute;
  top: 34px;
  left: 653px;
  float: right;
  text-align: center;
  color: #fff;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
}
b[data-v-78e33756] {
  font-weight: 600;
}
/* FOOTER */
.footer[data-v-78e33756] {
  width: 850px;
  padding-bottom: 25px;
  padding-top: 25px;
  height: 100%;
  float: left;
  z-index: 0 !important;
}
.footer-img[data-v-78e33756] {
  width: 90px;
}
.reference-num[data-v-78e33756] {
  color: #70A7DB;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  float: left;
  flex-grow: 100;
}
.reference-num p[data-v-78e33756] {
  margin-bottom: 10px;
  margin-top: 10px;
}
.reference-num.center[data-v-78e33756] {
  text-align: center;
}
/* PADDINGS */
.big-padding[data-v-78e33756] {
  padding: 25px 120px;
  box-sizing: border-box;
}
/* BUTTONS */
.div-button[data-v-78e33756] {
  display: flex;
  align-items: flex-end;
  padding-bottom: 10px;
  padding-top: 10px;
}
/* CONTAINERS */
.pg-container[data-v-78e33756] {
  display: table-cell;
  vertical-align: middle;
  padding-right: 10px;
  padding-left: 10px;
}
.pg-container-right[data-v-78e33756] {
  display: table-cell;
  vertical-align: middle;
  padding-right: 10px;
  padding-left: 10px;
  padding-left: 0px;
  padding-right: 5px;
  float: left;
}
.pg-container-left[data-v-78e33756] {
  display: table-cell;
  vertical-align: middle;
  padding-right: 10px;
  padding-left: 10px;
  padding-left: 5px;
  padding-right: 0px;
  float: left;
}
/* HEADERS & TEXTS */
p.money-header[data-v-78e33756] {
  color: #2B295E;
  font-family: 'Montserrat';
  font-size: 36px;
  margin-bottom: 5px;
  margin-top: 5px;
  font-weight: 300;
}
p.payment-text[data-v-78e33756] {
  color: #6a6a6a;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
  margin-top: 5px;
}
p.label[data-v-78e33756] {
  color: #2B295E;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: bold;
  margin: 10px 0px;
}
.circle-header[data-v-78e33756] {
  color: #2B295E;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: bold;
  margin-top: 5px;
  margin-bottom: 10px;
  text-align: center;
}
.circle-text[data-v-78e33756] {
  color: #6a6a6a;
  font-family: 'Montserrat';
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 400;
}
.circle-text b[data-v-78e33756] {
  font-weight: 600;
}
.dates-body[data-v-78e33756] {
  display: grid;
  grid-gap: 4px 7px;
  max-height: 218px;
  overflow-y: auto;
  height: 100%;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
}
.regular[data-v-78e33756] {
  color: #2B295E;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  padding-bottom: 10px;
  padding-top: 10px;
  margin: 0px;
  line-height: 20px;
  word-wrap: break-word;
}
.regular p[data-v-78e33756] {
  margin: 0px;
}
.regular b[data-v-78e33756] {
  font-weight: bold;
}
.regular.small[data-v-78e33756] {
  padding: 5px 0px 5px 0px;
}
.header-text[data-v-78e33756] {
  color: #2B295E;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  padding-bottom: 10px;
  padding-top: 10px;
  margin: 0px;
  line-height: 20px;
  word-wrap: break-word;
  font-weight: bold;
}
.header-text p[data-v-78e33756] {
  margin: 0px;
}
.header-text b[data-v-78e33756] {
  font-weight: bold;
}
.header-text.small[data-v-78e33756] {
  padding: 5px 0px 5px 0px;
}
.header-text.paddings[data-v-78e33756] {
  padding: 10px 0px;
}
.header-text.small[data-v-78e33756] {
  padding: 5px 0px 5px 0px;
}
.blue-field[data-v-78e33756] {
  background: rgba(125, 176, 226, 0.1);
  padding: 10px;
  margin-bottom: 10px;
}
.blue-field[data-v-78e33756]:first-of-type {
  border-radius: 3px 0px 0px 3px;
}
.blue-field[data-v-78e33756]:last-of-type {
  border-radius: 0px 3px 3px 0px;
}
.blue-field[data-v-78e33756]:only-of-type {
  border-radius: 3px;
}
.white-field[data-v-78e33756] {
  margin-bottom: 10px;
  font-style: italic;
}
.total[data-v-78e33756] {
  color: #2B295E;
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  margin: 10px 0px 0px 0px;
}
.total span[data-v-78e33756] {
  font-weight: bold;
}
.total__min[data-v-78e33756] {
  font-size: 12px;
}
.total__min[data-v-78e33756] {
  color: #2B295E;
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  margin: 5px 0px 30px 0px;
}
.regular-text[data-v-78e33756] {
  color: #2B295E;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  margin: 0px;
  padding-top: 12px;
  padding-bottom: 12px;
}
/* CSS GRID */
.mobile-grid[data-v-78e33756] {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: 0px;
  width: 100%;
}
/* CALL STATUSES */
.CallStatusLinked[data-v-78e33756] {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: 0px;
  width: 100%;
  grid-gap: 20px;
}
@media (min-width: 768px) {
.CallStatusLinked[data-v-78e33756] {
    grid-template-columns: minmax(0, 160px) minmax(0, 1fr);
}
}
.CallStatuses[data-v-78e33756] {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: 0px;
  width: 100%;
  grid-gap: 20px;
}
@media (min-width: 768px) {
.CallStatuses[data-v-78e33756] {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
}
}
.CallStatuses__circle[data-v-78e33756] {
  display: table;
  height: 100%;
}
.CallStatuses__circleContainer[data-v-78e33756] {
  display: table-cell;
  vertical-align: middle;
}
.CallStatuses__buttons[data-v-78e33756] {
  width: 50%;
  float: right;
  margin-top: 10px;
}
.CallStatuses__containerInfo[data-v-78e33756] {
  padding: 0px 15px 15px 0px;
  height: 345px;
  display: table;
}
.CallStatuses__form[data-v-78e33756] {
  padding: 15px 15px 15px 0px;
  height: 345px;
}
/* REFUND */
.Refund[data-v-78e33756] {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: 0px;
  width: 100%;
  margin-top: 20px;
}
@media (min-width: 768px) {
.Refund[data-v-78e33756] {
    grid-template-columns: minmax(0, 2fr) minmax(0, 1fr);
    grid-gap: 20px;
}
}
/* REFUND TRANSACTION */
.RefundTransaction[data-v-78e33756] {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: 0px;
  width: 100%;
}
@media (min-width: 768px) {
.RefundTransaction[data-v-78e33756] {
    grid-template-columns: minmax(0, 1fr) minmax(0, 2fr) minmax(0, 1fr);
}
}
@media (min-width: 768px) {
.RefundTransaction.add-column[data-v-78e33756] {
    grid-template-columns: minmax(0, 1fr) minmax(0, 2fr) minmax(0, 1fr) minmax(0, 170px);
}
}
.RefundTransaction__buttons[data-v-78e33756] {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: 0px;
  width: 100%;
  margin-top: 10px;
}
@media (min-width: 768px) {
.RefundTransaction__buttons[data-v-78e33756] {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
}
}
.RefundTransaction__buttons button[data-v-78e33756]:nth-child(2) {
  grid-column-start: 4;
}
.RefundTransaction--advanced[data-v-78e33756] {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: 0px;
  width: 100%;
}
@media (min-width: 768px) {
.RefundTransaction--advanced[data-v-78e33756] {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 2fr);
}
}
/* REFUND TRANSACTION DETAIL */
.RefundTransactionDetail[data-v-78e33756] {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: 0px;
  width: 100%;
}
@media (min-width: 768px) {
.RefundTransactionDetail[data-v-78e33756] {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    grid-gap: 0px;
}
}
/* REFUND TRANSACTION LIST */
.RefundTransactionList[data-v-78e33756] {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: 0px;
  width: 100%;
  margin: 10px 0px;
}
@media (min-width: 768px) {
.RefundTransactionList[data-v-78e33756] {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
}
}
.RefundTransactionList button[data-v-78e33756]:nth-child(2) {
  grid-column-start: 4;
}
/* REFUND TRANSACTION FORM */
.RefundTransactionForm__payment[data-v-78e33756] {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: 0px;
  width: 100%;
  margin-top: 20px;
}
@media (min-width: 768px) {
.RefundTransactionForm__payment[data-v-78e33756] {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
}
}
.RefundTransactionForm__card[data-v-78e33756] {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: 0px;
  width: 100%;
}
@media (min-width: 768px) {
.RefundTransactionForm__card[data-v-78e33756] {
    grid-template-columns: minmax(0, 2fr) minmax(0, 1fr) minmax(0, 1fr);
}
}
@media (min-width: 768px) {
.RefundTransactionForm__card.four-columns[data-v-78e33756] {
    grid-template-columns: minmax(0, 2fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
}
}
/* REFUND CONFIRM */
.RefundConfirm__buttons[data-v-78e33756] {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: 0px;
  width: 100%;
  margin: 10px 0px;
}
@media (min-width: 768px) {
.RefundConfirm__buttons[data-v-78e33756] {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    grid-gap: 20px;
}
}
.RefundConfirm__buttons button[data-v-78e33756]:nth-child(2) {
  grid-column-start: 3;
}
.RefundConfirm__buttons button[data-v-78e33756]:nth-child(3) {
  grid-column-start: 4;
}
.RefundConfirm__payment[data-v-78e33756] {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: 0px;
  width: 100%;
}
@media (min-width: 768px) {
.RefundConfirm__payment[data-v-78e33756] {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
}
}
.RefundConfirm__card[data-v-78e33756] {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: 0px;
  width: 100%;
}
@media (min-width: 768px) {
.RefundConfirm__card[data-v-78e33756] {
    grid-template-columns: minmax(0, 2fr) minmax(0, 1fr) minmax(0, 1fr);
}
}
/* REFUND RESULT */
.RefundResult[data-v-78e33756] {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: 0px;
  width: 100%;
  margin-bottom: 10px;
}
@media (min-width: 768px) {
.RefundResult[data-v-78e33756] {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    grid-gap: 7px;
}
}
.RefundResult button[data-v-78e33756]:nth-child(2) {
  grid-column-start: 4;
}
/* PAYMENT FORM */
.PaymentForm__total[data-v-78e33756] {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: 0px;
  width: 100%;
  margin-top: 0px;
}
@media (min-width: 768px) {
.PaymentForm__total[data-v-78e33756] {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    grid-gap: 7px;
}
}
.PaymentForm__total button[data-v-78e33756]:nth-child(2) {
  grid-column-start: 3;
}
.PaymentForm__method[data-v-78e33756] {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: 0px;
  width: 100%;
  margin-top: 10px;
}
@media (min-width: 768px) {
.PaymentForm__method[data-v-78e33756] {
    grid-template-columns: minmax(0, 3fr) minmax(0, 1fr);
    grid-gap: 20px;
}
}
.PaymentForm__address2[data-v-78e33756] {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: 0px;
  width: 100%;
  margin-top: 20px;
}
@media (min-width: 768px) {
.PaymentForm__address2[data-v-78e33756] {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
}
}
.PaymentForm__buttons[data-v-78e33756] {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: 0px;
  width: 100%;
  margin: 20px 0px 10px 0px;
  grid-gap: 20px;
}
@media (min-width: 768px) {
.PaymentForm__buttons[data-v-78e33756] {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    grid-gap: 7px;
}
}
.PaymentForm__buttons div[data-v-78e33756]:nth-child(2) {
  grid-column-start: 4;
}
.PaymentForm__infoContainer[data-v-78e33756] {
  float: left;
  width: 100%;
  margin-bottom: 10px;
}
.PaymentForm__info[data-v-78e33756] {
  color: #6a6a6a;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 20px;
  margin-top: 5px;
  font-style: italic;
}
.PaymentForm__paylink__info[data-v-78e33756] {
  margin-top: 20px;
  margin-bottom: 25px;
}
.PaymentForm__paylink__info__text[data-v-78e33756] {
  color: #6a6a6a;
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  font-weight: 400;
  margin-top: 5px;
  margin-left: 5px;
  margin-bottom: 10px;
}
.PaymentForm__paylink__info__text__green[data-v-78e33756] {
  color: #22B14C;
}
.PaymentForm__extraDiv[data-v-78e33756] {
  width: 100%;
  float: left;
  text-align: center;
  padding-bottom: 20px;
  padding-top: 40px;
}
.PaymentForm__extraLogo[data-v-78e33756] {
  width: 85px;
}
.PaymentForm__extraText[data-v-78e33756] {
  margin: 0px;
  text-align: center;
  font-family: 'Montserrat';
  font-size: 30px;
  color: #70A7DB;
  padding-top: 20px;
  font-weight: 600;
}
/* PAYMENT FORM AMOUNTS */
.PaymentFormAmounts__payment[data-v-78e33756] {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: 0px;
  width: 100%;
  margin-bottom: 10px;
}
@media (min-width: 768px) {
.PaymentFormAmounts__payment[data-v-78e33756] {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
}
}
@media (min-width: 768px) {
.PaymentFormAmounts__payment.add-column[data-v-78e33756] {
    grid-template-columns: minmax(0, 60px) minmax(0, calc(25% - 60px)) minmax(0, 25%) minmax(0, 25%) minmax(0, 25%);
}
}
.PaymentFormAmounts__payment_currencies[data-v-78e33756] {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: 0px;
  width: 100%;
  margin-bottom: 10px;
}
@media (min-width: 768px) {
.PaymentFormAmounts__payment_currencies[data-v-78e33756] {
    grid-template-columns: minmax(0, 12%) minmax(0, 23%) minmax(0, 20%) minmax(0, 25%) minmax(0, 20%);
}
}
@media (min-width: 768px) {
.PaymentFormAmounts__payment_currencies.add-column[data-v-78e33756] {
    grid-template-columns: minmax(0, 50px) minmax(0, 12%) minmax(0, calc(23% - 50px)) minmax(0, 20%) minmax(0, 25%) minmax(0, 20%);
}
}
.PaymentFormAmounts__reoccur[data-v-78e33756] {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: 0px;
  width: 100%;
  margin-bottom: 10px;
}
@media (min-width: 768px) {
.PaymentFormAmounts__reoccur[data-v-78e33756] {
    grid-template-columns: minmax(0, 35px) minmax(0, 35px) minmax(0, 80px) minmax(0, 160px) minmax(0, 55px) minmax(0, 38px) minmax(0, 100px) minmax(0, 127px) minmax(0, 1fr);
    grid-gap: 5px;
}
}
/* PAYMENT CONFIRM */
.PaymentConfirm[data-v-78e33756] {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: 0px;
  width: 100%;
}
@media (min-width: 768px) {
.PaymentConfirm[data-v-78e33756] {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
}
}
.PaymentConfirm__card[data-v-78e33756] {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: 0px;
  width: 100%;
}
@media (min-width: 768px) {
.PaymentConfirm__card[data-v-78e33756] {
    grid-template-columns: minmax(0, 2fr) minmax(0, 1fr) minmax(0, 1fr);
}
}
@media (min-width: 768px) {
.PaymentConfirm__card.add-column[data-v-78e33756] {
    grid-gap: 20px;
    grid-template-columns: minmax(0, 2fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
}
}
.PaymentConfirm__buttons[data-v-78e33756] {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: 0px;
  width: 100%;
  margin: 10px 0px;
  position: absolute;
}
@media (min-width: 768px) {
.PaymentConfirm__buttons[data-v-78e33756] {
    grid-gap: 20px;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
}
}
.PaymentConfirm__buttons button[data-v-78e33756]:nth-child(2) {
  grid-column-start: 3;
}
.PaymentConfirm__buttons button[data-v-78e33756]:nth-child(3) {
  grid-column-start: 4;
}
.PaymentConfirm__infoContainer[data-v-78e33756] {
  grid-column-start: 3;
  grid-column-end: 5;
}
.PaymentConfirm__info[data-v-78e33756] {
  color: #2B295E;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  margin: 0px;
  line-height: 125%;
}
.PaymentConfirm__buttonsContainer[data-v-78e33756] {
  position: relative;
  height: 60px;
}
/* PAYMENT RESULT */
.PaymentResult[data-v-78e33756] {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: 0px;
  width: 100%;
  margin-bottom: 10px;
  margin-top: 1.5rem;
}
@media (min-width: 768px) {
.PaymentResult[data-v-78e33756] {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    grid-gap: 7px;
}
}
.PaymentResult button[data-v-78e33756]:nth-child(2) {
  grid-column-start: 4;
}
/* ADMIN SEARCH */
.AdminSearch[data-v-78e33756] {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: 0px;
  width: 100%;
}
@media (min-width: 768px) {
.AdminSearch[data-v-78e33756] {
    grid-template-columns: minmax(0, 1fr) minmax(0, 2fr) minmax(0, 1fr);
    -ms-grid-columns: 1fr 2fr 1fr;
}
}
@media (min-width: 768px) {
.AdminSearch.four-column[data-v-78e33756] {
    grid-template-columns: minmax(0, 100px) minmax(0, 230px) minmax(0, 1fr) minmax(0, 1fr);
    -ms-grid-columns: minmax(0, 100px) minmax(0, 220px) minmax(0, 1fr) minmax(0, 1fr);
}
}
.AdminSearch--advanced[data-v-78e33756] {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: 0px;
  width: 100%;
}
@media (min-width: 768px) {
.AdminSearch--advanced[data-v-78e33756] {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 2fr);
    -ms-grid-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 2fr);
}
}
.AdminSearch__buttons[data-v-78e33756] {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: 0px;
  width: 100%;
  margin-top: 10px;
}
@media (min-width: 768px) {
.AdminSearch__buttons[data-v-78e33756] {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    -ms-grid-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
}
}
.AdminSearch__buttons button[data-v-78e33756]:nth-child(2) {
  grid-column-start: 4;
}
/* ADMIN EDIT FORM */
.AdminEditForm__payment[data-v-78e33756] {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: 0px;
  width: 100%;
}
@media (min-width: 768px) {
.AdminEditForm__payment[data-v-78e33756] {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    -ms-grid-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
}
}
.AdminEditForm__total[data-v-78e33756] {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: 0px;
  width: 100%;
}
@media (min-width: 768px) {
.AdminEditForm__total[data-v-78e33756] {
    grid-template-columns: minmax(0, 2fr) minmax(0, 1fr) minmax(0, 1fr);
    -ms-grid-columns: minmax(0, 2fr) minmax(0, 1fr) minmax(0, 1fr);
    grid-gap: 20px;
}
}
.AdminEditForm__buttons[data-v-78e33756] {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: 0px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}
@media (min-width: 768px) {
.AdminEditForm__buttons[data-v-78e33756] {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    -ms-grid-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    grid-gap: 20px;
}
}
.AdminEditForm__buttons button[data-v-78e33756]:nth-child(2) {
  grid-column-start: 4;
}
/* ADMIN PAYMENT METHOD */
.AdminPaymentMethod__address[data-v-78e33756] {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: 0px;
  width: 100%;
}
@media (min-width: 768px) {
.AdminPaymentMethod__address[data-v-78e33756] {
    grid-template-columns: minmax(0, 1fr) minmax(0, 3fr);
    -ms-grid-columns: minmax(0, 1fr) minmax(0, 3fr);
}
}
.AdminPaymentMethod__card[data-v-78e33756] {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: 0px;
  width: 100%;
}
@media (min-width: 768px) {
.AdminPaymentMethod__card[data-v-78e33756] {
    grid-template-columns: minmax(0, 2fr) minmax(0, 1fr) minmax(0, 1fr);
    -ms-grid-columns: minmax(0, 2fr) minmax(0, 1fr) minmax(0, 1fr);
}
}
.AdminPaymentMethod__method[data-v-78e33756] {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: 0px;
  width: 100%;
}
@media (min-width: 768px) {
.AdminPaymentMethod__method[data-v-78e33756] {
    grid-template-columns: minmax(0, 3fr) minmax(0, 1fr);
    -ms-grid-columns: minmax(0, 3fr) minmax(0, 1fr);
    grid-gap: 20px;
}
}
/* ADMIN SINGLE PAYMENT EDIT */
/* grid-template-columns: 50px 12% calc(23% - 50px) 20% 25% 20%;*/
.AdminSinglePaymentEdit[data-v-78e33756] {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: 0px;
  width: 100%;
  margin-bottom: 10px;
}
@media (min-width: 768px) {
.AdminSinglePaymentEdit[data-v-78e33756] {
    grid-template-columns: minmax(0, 50px) minmax(0, 12%) minmax(0, calc(23% - 50px)) minmax(0, 20%) minmax(0, 25%) minmax(0, 20%);
    -ms-grid-columns: minmax(0, 50px) minmax(0, 12%) minmax(0, calc(23% - 50px)) minmax(0, 20%) minmax(0, 25%) minmax(0, 20%);
}
}
.AdminSinglePaymentEdit__reoccur[data-v-78e33756] {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: 0px;
  width: 100%;
  margin-bottom: 10px;
}
@media (min-width: 768px) {
.AdminSinglePaymentEdit__reoccur[data-v-78e33756] {
    grid-template-columns: minmax(0, 35px) minmax(0, 35px) minmax(0, 80px) minmax(0, 160px) minmax(0, 55px) minmax(0, 38px) minmax(0, 100px) minmax(0, 127px) minmax(0, 1fr);
    -ms-grid-columns: minmax(0, 35px) minmax(0, 35px) minmax(0, 80px) minmax(0, 160px) minmax(0, 55px) minmax(0, 38px) minmax(0, 100px) minmax(0, 127px) minmax(0, 1fr);
    grid-gap: 5px;
}
}
.remove-label[data-v-78e33756] {
  margin-top: -39px;
}
/* ADMIN SUCCESSFUL PAGE */
.AdminSuccessfulPage__button[data-v-78e33756] {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: 0px;
  width: 100%;
  margin-top: 37px;
}
@media (min-width: 768px) {
.AdminSuccessfulPage__button[data-v-78e33756] {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    -ms-grid-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    grid-gap: 7px;
}
}
.AdminSuccessfulPage__button button[data-v-78e33756]:nth-child(2) {
  grid-column-start: 4;
}
/* ADMIN CONFIRM PAGE */
.AdminConfirmPage__payment[data-v-78e33756] {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: 0px;
  width: 100%;
}
@media (min-width: 768px) {
.AdminConfirmPage__payment[data-v-78e33756] {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    -ms-grid-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
}
}
.AdminConfirmPage__buttons[data-v-78e33756] {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: 0px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}
@media (min-width: 768px) {
.AdminConfirmPage__buttons[data-v-78e33756] {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    -ms-grid-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    grid-gap: 20px;
}
}
.AdminConfirmPage__buttons button[data-v-78e33756]:nth-child(2) {
  grid-column-start: 3;
}
.AdminConfirmPage__buttons button[data-v-78e33756]:nth-child(3) {
  grid-column-start: 4;
}
/* ADMIN RESULTS */
.AdminResults[data-v-78e33756] {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: 0px;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 10px;
}
@media (min-width: 768px) {
.AdminResults[data-v-78e33756] {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    -ms-grid-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    grid-gap: 20px;
}
}
.AdminResults button[data-v-78e33756]:nth-child(2) {
  grid-column-start: 3;
}
.AdminResults button[data-v-78e33756]:nth-child(3) {
  grid-column-start: 4;
}
/* LINK DATA */
.LinkData-header[data-v-78e33756] {
  color: #2B295E;
  font-family: 'Montserrat';
  font-size: 16px;
  text-align: center;
  font-weight: 600;
  margin: 0px;
  padding-bottom: 10px;
}
.LinkData-container[data-v-78e33756] {
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  grid-gap: 5px;
}
.LinkData-title[data-v-78e33756] {
  color: #6A6A6A;
  font-family: 'Montserrat';
  margin: 0px;
  padding-bottom: 10px;
  font-weight: 600;
  font-size: 14px;
}
.LinkData-text[data-v-78e33756] {
  color: #6A6A6A;
  margin: 0px;
  padding-bottom: 10px;
  font-family: 'Montserrat';
  font-size: 14px;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  /*@light-blue: #70A7DB;
@dark-blue: #2B295E;
@pink: #BF087F;*/
  /* TABS */
.box-width[data-v-78e33756] {
    width: 100%;
}
[data-v-78e33756] .vue-tabs {
    border-radius: 3px 3px 0px 0px !important;
    width: 850px;
}
[data-v-78e33756] .vue-tabs .nav-tabs-navigation {
    height: 79px;
    padding: 20px 15px 0px 15px;
    overflow: hidden;
}
[data-v-78e33756] .vue-tabs .nav-tabs-navigation .nav-tabs-wrapper {
    overflow: hidden;
    height: 79px;
}
[data-v-78e33756] .vue-tabs .nav-tabs {
    border-bottom: 0px solid #fff !important;
    margin-top: 0px !important;
}
[data-v-78e33756] .vue-tabs .nav-tabs li > a {
    line-height: 1 !important;
    height: 59px;
    box-sizing: border-box;
    margin-right: -1px;
    padding: 20px 30px;
    border-color: rgba(255, 255, 255, 0.3);
    border-radius: 3px 3px 0px 0px;
    border-bottom: 0px solid transparent;
    text-align: center;
}
[data-v-78e33756] .vue-tabs .nav-tabs li > a:hover {
    border-color: rgba(255, 255, 255, 0.3);
}
[data-v-78e33756] .vue-tabs .nav-tabs li > a span {
    color: white;
    font-family: 'Montserrat';
    font-size: 16px;
    font-weight: bold;
}
[data-v-78e33756] .vue-tabs .nav-tabs li.active > a,[data-v-78e33756] .vue-tabs .nav-tabs li.active > a:focus,[data-v-78e33756] .vue-tabs .nav-tabs li.active > a:hover {
    border-radius: 3px 3px 0px 0px;
    border: 1px solid white;
    box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.1);
    width: auto;
    background: white;
}
[data-v-78e33756] .vue-tabs .nav-tabs li.active > a span,[data-v-78e33756] .vue-tabs .nav-tabs li.active > a:focus span,[data-v-78e33756] .vue-tabs .nav-tabs li.active > a:hover span {
    color: #2B295E;
    font-family: 'Montserrat';
    font-size: 16px;
}
[data-v-78e33756] .vue-tabs.green {
    background: #66c400;
}
[data-v-78e33756] .vue-tabs.red {
    background: #d0021b;
}
[data-v-78e33756] .vue-tabs.blue {
    background: #70A7DB;
}
[data-v-78e33756] .vue-tabs.yellow {
    background: #f8c41c;
}
[data-v-78e33756] .vue-tabs .nav > li {
    min-width: 126px;
    height: 59px;
}
[data-v-78e33756] .vue-tabs .nav > li a:hover,[data-v-78e33756] .vue-tabs .nav > li a:focus {
    text-decoration: none;
    background-color: transparent;
}
[data-v-78e33756] .tab-content {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.1);
    padding: 15px 20px;
    height: auto;
    min-height: 400px;
    float: left;
    width: 850px;
    box-sizing: border-box;
    background: #ffffff;
    border-top: 0px solid transparent;
}
.caller-section[data-v-78e33756] {
    width: 135px;
    position: absolute;
    top: 13px;
    left: 650px;
    float: right;
    text-align: center;
    color: #ffffff;
    font-family: 'Montserrat';
    font-size: 16px;
    font-weight: 500;
}
.caller-section p[data-v-78e33756] {
    margin: 5px 0px 5px 0px;
}
.pin-caller-section[data-v-78e33756] {
    width: 135px;
    position: absolute;
    top: 30px;
    left: 715px;
    float: right;
    text-align: center;
    color: #fff;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
}
  /* FOOTER */
.footer[data-v-78e33756] {
    width: 850px;
    padding-bottom: 25px;
    padding-top: 25px;
    height: 100%;
    float: left;
    z-index: 0 !important;
}
.footer-img[data-v-78e33756] {
    width: 218px;
}
.reference-num[data-v-78e33756] {
    color: #70A7DB;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    float: left;
    flex-grow: 100;
}
.reference-num p[data-v-78e33756] {
    margin-bottom: 10px;
    margin-top: 10px;
}
.reference-num.center[data-v-78e33756] {
    text-align: center;
}
  /* PADDINGS */
.big-padding[data-v-78e33756] {
    padding: 25px 120px;
    box-sizing: border-box;
}
  /* BUTTONS */
.div-button[data-v-78e33756] {
    display: flex;
    align-items: flex-end;
    padding-bottom: 10px;
    padding-top: 10px;
}
  /* CONTAINERS */
.pg-container[data-v-78e33756] {
    display: table-cell;
    vertical-align: middle;
    padding-right: 10px;
    padding-left: 10px;
}
.pg-container-right[data-v-78e33756] {
    display: table-cell;
    vertical-align: middle;
    padding-right: 10px;
    padding-left: 10px;
    padding-left: 0px;
    padding-right: 5px;
    float: left;
}
.pg-container-left[data-v-78e33756] {
    display: table-cell;
    vertical-align: middle;
    padding-right: 10px;
    padding-left: 10px;
    padding-left: 5px;
    padding-right: 0px;
    float: left;
}
  /* HEADERS & TEXTS */
p.money-header[data-v-78e33756] {
    color: #2B295E;
    font-family: 'Montserrat';
    font-size: 36px;
    margin-bottom: 5px;
    margin-top: 5px;
    font-weight: 300;
}
p.payment-text[data-v-78e33756] {
    color: #6a6a6a;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 5px;
    margin-top: 5px;
}
p.label[data-v-78e33756] {
    color: #2B295E;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: bold;
    margin: 10px 0px;
}
.circle-header[data-v-78e33756] {
    color: #2B295E;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
}
.circle-text[data-v-78e33756] {
    color: #6a6a6a;
    font-family: 'Montserrat';
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: 400;
}
.circle-text b[data-v-78e33756] {
    font-weight: 600;
}
.dates-body[data-v-78e33756] {
    display: grid;
    grid-gap: 4px 7px;
    max-height: 218px;
    overflow-y: auto;
    height: 100%;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
}
.regular[data-v-78e33756] {
    color: #2B295E;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    padding-bottom: 10px;
    padding-top: 10px;
    margin: 0px;
    line-height: 20px;
    word-wrap: break-word;
}
.regular p[data-v-78e33756] {
    margin: 0px;
}
.regular b[data-v-78e33756] {
    font-weight: bold;
}
.regular.small[data-v-78e33756] {
    padding: 5px 0px 5px 0px;
}
.header-text[data-v-78e33756] {
    color: #2B295E;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    padding-bottom: 10px;
    padding-top: 10px;
    margin: 0px;
    line-height: 20px;
    word-wrap: break-word;
    font-weight: bold;
}
.header-text p[data-v-78e33756] {
    margin: 0px;
}
.header-text b[data-v-78e33756] {
    font-weight: bold;
}
.header-text.small[data-v-78e33756] {
    padding: 5px 0px 5px 0px;
}
.header-text.paddings[data-v-78e33756] {
    padding: 10px 0px;
}
.header-text.small[data-v-78e33756] {
    padding: 5px 0px 5px 0px;
}
.blue-field[data-v-78e33756] {
    background: rgba(125, 176, 226, 0.1);
    padding: 10px;
    margin-bottom: 10px;
}
.blue-field[data-v-78e33756]:first-of-type {
    border-radius: 3px 0px 0px 3px;
}
.blue-field[data-v-78e33756]:last-of-type {
    border-radius: 0px 3px 3px 0px;
}
.blue-field[data-v-78e33756]:only-of-type {
    border-radius: 3px;
}
.total[data-v-78e33756] {
    color: #2B295E;
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    margin: 20px 0px 10px 0px;
}
.total span[data-v-78e33756] {
    font-weight: bold;
}
.regular-text[data-v-78e33756] {
    color: #2B295E;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    margin: 0px;
    padding-top: 12px;
    padding-bottom: 12px;
}
  /* CSS GRID */
.mobile-grid[data-v-78e33756] {
    display: -ms-grid;
    -ms-grid-columns: 1fr;
    grid-gap: 0px;
    width: 100%;
}
  /* REFUND */
.Refund[data-v-78e33756] {
    display: -ms-grid;
    -ms-grid-columns: 1fr;
    grid-gap: 0px;
    width: 100%;
    margin-top: 20px;
}
  /* REFUND TRANSACTION */
.RefundTransaction[data-v-78e33756] {
    display: -ms-grid;
    -ms-grid-columns: 1fr;
    grid-gap: 0px;
    width: 100%;
}
.RefundTransaction__buttons[data-v-78e33756] {
    display: -ms-grid;
    -ms-grid-columns: 1fr;
    grid-gap: 0px;
    width: 100%;
    margin-top: 10px;
}
.RefundTransaction--advanced[data-v-78e33756] {
    display: -ms-grid;
    -ms-grid-columns: 1fr;
    grid-gap: 0px;
    width: 100%;
}
  /* REFUND TRANSACTION DETAIL */
.RefundTransactionDetail[data-v-78e33756] {
    display: -ms-grid;
    -ms-grid-columns: 1fr;
    grid-gap: 0px;
    width: 100%;
}
  /* REFUND TRANSACTION LIST */
.RefundTransactionList[data-v-78e33756] {
    display: -ms-grid;
    -ms-grid-columns: 1fr;
    grid-gap: 0px;
    width: 100%;
    margin: 10px 0px;
}
.RefundTransactionList button[data-v-78e33756]:nth-child(1) {
    -ms-grid-column: 1;
}
.RefundTransactionList button[data-v-78e33756]:nth-child(2) {
    -ms-grid-column: 4;
}
  /* REFUND TRANSACTION FORM */
.RefundTransactionForm__payment[data-v-78e33756] {
    display: -ms-grid;
    -ms-grid-columns: 1fr;
    grid-gap: 0px;
    width: 100%;
    margin-top: 20px;
}
.RefundTransactionForm__card[data-v-78e33756] {
    display: -ms-grid;
    -ms-grid-columns: 1fr;
    grid-gap: 0px;
    width: 100%;
}
  /* REFUND CONFIRM */
.RefundConfirm__buttons[data-v-78e33756] {
    display: -ms-grid;
    -ms-grid-columns: 1fr;
    grid-gap: 0px;
    width: 100%;
    margin: 10px 0px;
}
.RefundConfirm__payment[data-v-78e33756] {
    display: -ms-grid;
    -ms-grid-columns: 1fr;
    grid-gap: 0px;
    width: 100%;
}
.RefundConfirm__card[data-v-78e33756] {
    display: -ms-grid;
    -ms-grid-columns: 1fr;
    grid-gap: 0px;
    width: 100%;
}
  /* REFUND RESULT */
.RefundResult[data-v-78e33756] {
    display: -ms-grid;
    -ms-grid-columns: 1fr;
    grid-gap: 0px;
    width: 100%;
    margin-bottom: 10px;
}
.RefundResult button[data-v-78e33756]:nth-child(1) {
    -ms-grid-column: 1;
}
.RefundResult button[data-v-78e33756]:nth-child(2) {
    -ms-grid-column: 4;
}
  /* PAYMENT FORM */
.PaymentForm__total[data-v-78e33756] {
    display: -ms-grid;
    -ms-grid-columns: 1fr;
    grid-gap: 0px;
    width: 100%;
    margin-top: 10px;
}
.PaymentForm__total button[data-v-78e33756]:nth-child(1) {
    -ms-grid-column: 1;
}
.PaymentForm__total button[data-v-78e33756]:nth-child(2) {
    -ms-grid-column: 4;
}
.PaymentForm__method[data-v-78e33756] {
    display: -ms-grid;
    -ms-grid-columns: 1fr;
    grid-gap: 0px;
    width: 100%;
    margin-top: 10px;
}
.PaymentForm__address[data-v-78e33756] {
    display: -ms-grid;
    -ms-grid-columns: 1fr;
    grid-gap: 0px;
    width: 100%;
    margin-top: 10px;
}
.PaymentForm__buttons[data-v-78e33756] {
    display: -ms-grid;
    -ms-grid-columns: 1fr;
    grid-gap: 0px;
    width: 100%;
    margin: 20px 0px 10px 0px;
}
.PaymentForm__buttons div[data-v-78e33756]:nth-child(2) {
    -ms-grid-column: 4;
}
.PaymentForm__infoContainer[data-v-78e33756] {
    float: left;
    width: 100%;
}
.PaymentForm__info[data-v-78e33756] {
    color: #6a6a6a;
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 20px;
    margin-top: 5px;
    font-style: italic;
}
  /* PAYMENT FORM AMOUNTS */
.PaymentFormAmounts__payment[data-v-78e33756] {
    display: -ms-grid;
    -ms-grid-columns: 1fr;
    grid-gap: 0px;
    width: 100%;
    margin-bottom: 10px;
}
.PaymentFormAmounts__reoccur[data-v-78e33756] {
    display: -ms-grid;
    -ms-grid-columns: 1fr;
    grid-gap: 0px;
    width: 100%;
    margin-bottom: 10px;
}
  /* PAYMENT CONFIRM */
.PaymentConfirm[data-v-78e33756] {
    display: -ms-grid;
    -ms-grid-columns: 1fr;
    grid-gap: 0px;
    width: 100%;
}
.PaymentConfirm__card[data-v-78e33756] {
    display: -ms-grid;
    -ms-grid-columns: 1fr;
    grid-gap: 0px;
    width: 100%;
}
.PaymentConfirm__buttons[data-v-78e33756] {
    display: -ms-grid;
    -ms-grid-columns: 1fr;
    grid-gap: 0px;
    width: 100%;
    margin: 10px 0px;
}
  /* PAYMENT RESULT */
.PaymentResult[data-v-78e33756] {
    display: -ms-grid;
    -ms-grid-columns: 1fr;
    grid-gap: 0px;
    width: 100%;
    margin-bottom: 10px;
}
  /* ADMIN SEARCH */
.AdminSearch[data-v-78e33756] {
    display: -ms-grid;
    -ms-grid-columns: 1fr;
    grid-gap: 0px;
    width: 100%;
}
.AdminSearch--advanced[data-v-78e33756] {
    display: -ms-grid;
    -ms-grid-columns: 1fr;
    grid-gap: 0px;
    width: 100%;
}
.AdminSearch__buttons[data-v-78e33756] {
    display: -ms-grid;
    -ms-grid-columns: 1fr;
    grid-gap: 0px;
    width: 100%;
    margin-top: 10px;
}
  /* ADMIN EDIT FORM */
.AdminEditForm__payment[data-v-78e33756] {
    display: -ms-grid;
    -ms-grid-columns: 1fr;
    grid-gap: 0px;
    width: 100%;
}
.AdminEditForm__total[data-v-78e33756] {
    display: -ms-grid;
    -ms-grid-columns: 1fr;
    grid-gap: 0px;
    width: 100%;
}
.AdminEditForm__total p[data-v-78e33756] {
    -ms-grid-column: 1;
}
.AdminEditForm__total button[data-v-78e33756]:nth-child(2) {
    -ms-grid-column: 2;
}
.AdminEditForm__total button[data-v-78e33756]:nth-child(3) {
    -ms-grid-column: 3;
}
.AdminEditForm__buttons[data-v-78e33756] {
    display: -ms-grid;
    -ms-grid-columns: 1fr;
    grid-gap: 0px;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
}
  /* ADMIN PAYMENT METHOD */
.AdminPaymentMethod__address[data-v-78e33756] {
    display: -ms-grid;
    -ms-grid-columns: 1fr;
    grid-gap: 0px;
    width: 100%;
}
.AdminPaymentMethod__card[data-v-78e33756] {
    display: -ms-grid;
    -ms-grid-columns: 1fr;
    grid-gap: 0px;
    width: 100%;
}
.AdminPaymentMethod__method[data-v-78e33756] {
    display: -ms-grid;
    -ms-grid-columns: 1fr;
    grid-gap: 0px;
    width: 100%;
}
  /* ADMIN SINGLE PAYMENT EDIT */
.AdminSinglePaymentEdit[data-v-78e33756] {
    display: -ms-grid;
    -ms-grid-columns: 1fr;
    grid-gap: 0px;
    width: 100%;
    margin-bottom: 10px;
}
.AdminSinglePaymentEdit__reoccur[data-v-78e33756] {
    display: -ms-grid;
    -ms-grid-columns: 1fr;
    grid-gap: 0px;
    width: 100%;
    margin-bottom: 10px;
}
  /* ADMIN SUCCESSFUL PAGE */
.AdminSuccessfulPage__button[data-v-78e33756] {
    display: -ms-grid;
    -ms-grid-columns: 1fr;
    grid-gap: 0px;
    width: 100%;
    margin-top: 10px;
}
  /* ADMIN CONFIRM PAGE */
.AdminConfirmPage__payment[data-v-78e33756] {
    display: -ms-grid;
    -ms-grid-columns: 1fr;
    grid-gap: 0px;
    width: 100%;
}
.AdminConfirmPage__buttons[data-v-78e33756] {
    display: -ms-grid;
    -ms-grid-columns: 1fr;
    grid-gap: 0px;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
}
  /* ADMIN RESULTS */
.AdminResults[data-v-78e33756] {
    display: -ms-grid;
    -ms-grid-columns: 1fr;
    grid-gap: 0px;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 10px;
}
}
@media screen and (-ms-high-contrast: active) and (min-width: 992px), (-ms-high-contrast: none) and (min-width: 992px) {
.box-width[data-v-78e33756] {
    width: 780px;
}
}
@media screen and (-ms-high-contrast: active) and (min-width: 768px), (-ms-high-contrast: none) and (min-width: 768px) {
.Refund[data-v-78e33756] {
    -ms-grid-columns: 2fr 1fr;
    grid-gap: 20px;
}
}
@media screen and (-ms-high-contrast: active) and (min-width: 768px), (-ms-high-contrast: none) and (min-width: 768px) {
.RefundTransaction[data-v-78e33756] {
    -ms-grid-columns: 1fr 2fr 1fr;
}
.RefundTransaction div[data-v-78e33756]:nth-child(1) {
    -ms-grid-column: 1;
}
.RefundTransaction div[data-v-78e33756]:nth-child(2) {
    -ms-grid-column: 2;
}
.RefundTransaction div[data-v-78e33756]:nth-child(3) {
    -ms-grid-column: 3;
}
}
@media screen and (-ms-high-contrast: active) and (min-width: 768px), (-ms-high-contrast: none) and (min-width: 768px) {
.RefundTransaction.add-column[data-v-78e33756] {
    -ms-grid-columns-columns: 1fr 2fr 1fr 1fr;
}
}
@media screen and (-ms-high-contrast: active) and (min-width: 768px), (-ms-high-contrast: none) and (min-width: 768px) {
.RefundTransaction__buttons[data-v-78e33756] {
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
}
.RefundTransaction__buttons button[data-v-78e33756]:nth-child(1) {
    -ms-grid-column: 1;
}
.RefundTransaction__buttons button[data-v-78e33756]:nth-child(2) {
    -ms-grid-column: 4;
}
}
@media screen and (-ms-high-contrast: active) and (min-width: 768px), (-ms-high-contrast: none) and (min-width: 768px) {
.RefundTransaction--advanced[data-v-78e33756] {
    -ms-grid-columns: 1fr 1fr 2fr;
}
.RefundTransaction--advanced div[data-v-78e33756]:nth-child(1) {
    -ms-grid-column: 1;
}
.RefundTransaction--advanced div[data-v-78e33756]:nth-child(2) {
    -ms-grid-column: 2;
}
.RefundTransaction--advanced div[data-v-78e33756]:nth-child(3) {
    -ms-grid-column: 3;
}
}
@media screen and (-ms-high-contrast: active) and (min-width: 768px), (-ms-high-contrast: none) and (min-width: 768px) {
.RefundTransactionDetail[data-v-78e33756] {
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-gap: 0px;
}
.RefundTransactionDetail div[data-v-78e33756]:nth-child(1) {
    -ms-grid-column: 1;
    -ms-grid-row: 1;
}
.RefundTransactionDetail div[data-v-78e33756]:nth-child(2) {
    -ms-grid-column: 2;
    -ms-grid-row: 1;
}
.RefundTransactionDetail div[data-v-78e33756]:nth-child(3) {
    -ms-grid-column: 3;
    -ms-grid-row: 1;
}
.RefundTransactionDetail div[data-v-78e33756]:nth-child(4) {
    -ms-grid-column: 1;
    -ms-grid-row: 2;
}
.RefundTransactionDetail div[data-v-78e33756]:nth-child(5) {
    -ms-grid-column: 2;
    -ms-grid-row: 2;
}
.RefundTransactionDetail div[data-v-78e33756]:nth-child(6) {
    -ms-grid-column: 3;
    -ms-grid-row: 2;
}
.RefundTransactionDetail div[data-v-78e33756]:nth-child(7) {
    -ms-grid-column: 1;
    -ms-grid-row: 3;
}
.RefundTransactionDetail div[data-v-78e33756]:nth-child(8) {
    -ms-grid-column: 2;
    -ms-grid-row: 3;
}
.RefundTransactionDetail div[data-v-78e33756]:nth-child(9) {
    -ms-grid-column: 3;
    -ms-grid-row: 3;
}
.RefundTransactionDetail div[data-v-78e33756]:nth-child(10) {
    -ms-grid-column: 1;
    -ms-grid-row: 4;
}
.RefundTransactionDetail div[data-v-78e33756]:nth-child(11) {
    -ms-grid-column: 2;
    -ms-grid-row: 4;
}
.RefundTransactionDetail div[data-v-78e33756]:nth-child(12) {
    -ms-grid-column: 3;
    -ms-grid-row: 4;
}
}
@media screen and (-ms-high-contrast: active) and (min-width: 768px), (-ms-high-contrast: none) and (min-width: 768px) {
.RefundTransactionList[data-v-78e33756] {
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
}
}
@media screen and (-ms-high-contrast: active) and (min-width: 768px), (-ms-high-contrast: none) and (min-width: 768px) {
.RefundTransactionForm__payment[data-v-78e33756] {
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
}
.RefundTransactionForm__payment div[data-v-78e33756]:nth-child(1) {
    -ms-grid-column: 1;
}
.RefundTransactionForm__payment div[data-v-78e33756]:nth-child(2) {
    -ms-grid-column: 2;
}
.RefundTransactionForm__payment div[data-v-78e33756]:nth-child(3) {
    -ms-grid-column: 3;
}
.RefundTransactionForm__payment div[data-v-78e33756]:nth-child(4) {
    -ms-grid-column: 4;
}
}
@media screen and (-ms-high-contrast: active) and (min-width: 768px), (-ms-high-contrast: none) and (min-width: 768px) {
.RefundTransactionForm__card[data-v-78e33756] {
    -ms-grid-columns: 2fr 1fr 1fr;
}
.RefundTransactionForm__card label[data-v-78e33756]:nth-child(1) {
    -ms-grid-column: 1;
}
.RefundTransactionForm__card label[data-v-78e33756]:nth-child(2) {
    -ms-grid-column: 2;
}
.RefundTransactionForm__card label[data-v-78e33756]:nth-child(3) {
    -ms-grid-column: 3;
}
.RefundTransactionForm__card p[data-v-78e33756]:nth-child(1) {
    -ms-grid-column: 1;
}
.RefundTransactionForm__card p[data-v-78e33756]:nth-child(2) {
    -ms-grid-column: 2;
}
.RefundTransactionForm__card p[data-v-78e33756]:nth-child(3) {
    -ms-grid-column: 3;
}
}
@media screen and (-ms-high-contrast: active) and (min-width: 768px), (-ms-high-contrast: none) and (min-width: 768px) {
.RefundTransactionForm__card.four-columns[data-v-78e33756] {
    -ms-grid-columns: 2fr 1fr 1fr 1fr;
}
.RefundTransactionForm__card.four-columns div[data-v-78e33756]:nth-child(1) {
    -ms-grid-column: 1;
}
.RefundTransactionForm__card.four-columns div[data-v-78e33756]:nth-child(2) {
    -ms-grid-column: 2;
}
.RefundTransactionForm__card.four-columns div[data-v-78e33756]:nth-child(3) {
    -ms-grid-column: 3;
}
.RefundTransactionForm__card.four-columns div[data-v-78e33756]:nth-child(4) {
    -ms-grid-column: 4;
}
}
@media screen and (-ms-high-contrast: active) and (min-width: 768px), (-ms-high-contrast: none) and (min-width: 768px) {
.RefundConfirm__buttons[data-v-78e33756] {
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
}
.RefundConfirm__buttons button[data-v-78e33756]:nth-child(2) {
    -ms-grid-column: 1;
}
.RefundConfirm__buttons button[data-v-78e33756]:nth-child(3) {
    -ms-grid-column: 4;
}
}
@media screen and (-ms-high-contrast: active) and (min-width: 768px), (-ms-high-contrast: none) and (min-width: 768px) {
.RefundConfirm__payment[data-v-78e33756] {
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
}
.RefundConfirm__payment label[data-v-78e33756]:nth-child(1) {
    -ms-grid-column: 1;
}
.RefundConfirm__payment label[data-v-78e33756]:nth-child(2) {
    -ms-grid-column: 2;
}
.RefundConfirm__payment label[data-v-78e33756]:nth-child(3) {
    -ms-grid-column: 3;
}
.RefundConfirm__payment label[data-v-78e33756]:nth-child(4) {
    -ms-grid-column: 4;
}
.RefundConfirm__payment p[data-v-78e33756]:nth-child(1) {
    -ms-grid-column: 1;
}
.RefundConfirm__payment p[data-v-78e33756]:nth-child(2) {
    -ms-grid-column: 2;
}
.RefundConfirm__payment p[data-v-78e33756]:nth-child(3) {
    -ms-grid-column: 3;
}
.RefundConfirm__payment p[data-v-78e33756]:nth-child(4) {
    -ms-grid-column: 4;
}
}
@media screen and (-ms-high-contrast: active) and (min-width: 768px), (-ms-high-contrast: none) and (min-width: 768px) {
.RefundConfirm__card[data-v-78e33756] {
    -ms-grid-columns: 2fr 1fr 1fr;
}
.RefundConfirm__card label[data-v-78e33756]:nth-child(1) {
    -ms-grid-column: 1;
}
.RefundConfirm__card label[data-v-78e33756]:nth-child(2) {
    -ms-grid-column: 2;
}
.RefundConfirm__card label[data-v-78e33756]:nth-child(3) {
    -ms-grid-column: 3;
}
.RefundConfirm__card p[data-v-78e33756]:nth-child(1) {
    -ms-grid-column: 1;
}
.RefundConfirm__card p[data-v-78e33756]:nth-child(2) {
    -ms-grid-column: 2;
}
.RefundConfirm__card p[data-v-78e33756]:nth-child(3) {
    -ms-grid-column: 3;
}
}
@media screen and (-ms-high-contrast: active) and (min-width: 768px), (-ms-high-contrast: none) and (min-width: 768px) {
.RefundResult[data-v-78e33756] {
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 7px;
}
}
@media screen and (-ms-high-contrast: active) and (min-width: 768px), (-ms-high-contrast: none) and (min-width: 768px) {
.PaymentForm__total[data-v-78e33756] {
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 7px;
}
}
@media screen and (-ms-high-contrast: active) and (min-width: 768px), (-ms-high-contrast: none) and (min-width: 768px) {
.PaymentForm__method[data-v-78e33756] {
    -ms-grid-columns: 3fr 1fr;
    grid-gap: 20px;
}
.PaymentForm__method div[data-v-78e33756]:nth-child(1) {
    -ms-grid-column: 1;
}
.PaymentForm__method div[data-v-78e33756]:nth-child(2) {
    -ms-grid-column: 2;
    margin-left: 20px;
}
}
@media screen and (-ms-high-contrast: active) and (min-width: 768px), (-ms-high-contrast: none) and (min-width: 768px) {
.PaymentForm__address[data-v-78e33756] {
    -ms-grid-columns: 1fr 3fr;
}
.PaymentForm__address div[data-v-78e33756]:nth-child(1) {
    -ms-grid-column: 1;
}
.PaymentForm__address div[data-v-78e33756]:nth-child(2) {
    -ms-grid-column: 2;
}
}
@media screen and (-ms-high-contrast: active) and (min-width: 768px), (-ms-high-contrast: none) and (min-width: 768px) {
.PaymentForm__buttons[data-v-78e33756] {
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 7px;
}
}
@media screen and (-ms-high-contrast: active) and (min-width: 768px), (-ms-high-contrast: none) and (min-width: 768px) {
.PaymentFormAmounts__payment[data-v-78e33756] {
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
}
.PaymentFormAmounts__payment div[data-v-78e33756]:nth-child(1) {
    -ms-grid-column: 1;
}
.PaymentFormAmounts__payment div[data-v-78e33756]:nth-child(2) {
    -ms-grid-column: 2;
}
.PaymentFormAmounts__payment div[data-v-78e33756]:nth-child(3) {
    -ms-grid-column: 3;
}
.PaymentFormAmounts__payment div[data-v-78e33756]:nth-child(4) {
    -ms-grid-column: 4;
}
}
@media screen and (-ms-high-contrast: active) and (min-width: 768px), (-ms-high-contrast: none) and (min-width: 768px) {
.PaymentFormAmounts__payment.add-column[data-v-78e33756] {
    -ms-grid-columns: 60px calc(25% - 60px) 25% 25% 25%;
}
.PaymentFormAmounts__payment.add-column div[data-v-78e33756]:nth-child(1) {
    -ms-grid-column: 1;
}
.PaymentFormAmounts__payment.add-column div[data-v-78e33756]:nth-child(2) {
    -ms-grid-column: 2;
}
.PaymentFormAmounts__payment.add-column div[data-v-78e33756]:nth-child(3) {
    -ms-grid-column: 3;
}
.PaymentFormAmounts__payment.add-column div[data-v-78e33756]:nth-child(4) {
    -ms-grid-column: 4;
}
.PaymentFormAmounts__payment.add-column div[data-v-78e33756]:nth-child(5) {
    -ms-grid-column: 5;
}
}
@media screen and (-ms-high-contrast: active) and (min-width: 768px), (-ms-high-contrast: none) and (min-width: 768px) {
.PaymentFormAmounts__reoccur[data-v-78e33756] {
    -ms-grid-columns: 95px 40px 75px 1fr 20px 40px 100px 1fr;
    grid-gap: 5px;
}
.PaymentFormAmounts__reoccur p[data-v-78e33756]:nth-child(1) {
    -ms-grid-column: 1;
}
.PaymentFormAmounts__reoccur div[data-v-78e33756]:nth-child(2) {
    -ms-grid-column: 2;
}
.PaymentFormAmounts__reoccur p[data-v-78e33756]:nth-child(3) {
    -ms-grid-column: 3;
}
.PaymentFormAmounts__reoccur div[data-v-78e33756]:nth-child(4) {
    -ms-grid-column: 4;
}
.PaymentFormAmounts__reoccur p[data-v-78e33756]:nth-child(5) {
    -ms-grid-column: 5;
}
.PaymentFormAmounts__reoccur div[data-v-78e33756]:nth-child(6) {
    -ms-grid-column: 6;
}
.PaymentFormAmounts__reoccur p[data-v-78e33756]:nth-child(7) {
    -ms-grid-column: 7;
}
.PaymentFormAmounts__reoccur div[data-v-78e33756]:nth-child(8) {
    -ms-grid-column: 8;
}
}
@media screen and (-ms-high-contrast: active) and (min-width: 768px), (-ms-high-contrast: none) and (min-width: 768px) {
.PaymentConfirm[data-v-78e33756] {
    -ms-grid-columns: 1fr 1fr 1fr;
}
.PaymentConfirm label[data-v-78e33756]:nth-child(1) {
    -ms-grid-column: 1;
}
.PaymentConfirm label[data-v-78e33756]:nth-child(2) {
    -ms-grid-column: 2;
}
.PaymentConfirm label[data-v-78e33756]:nth-child(3) {
    -ms-grid-column: 3;
}
.PaymentConfirm p[data-v-78e33756]:nth-child(1) {
    -ms-grid-column: 1;
}
.PaymentConfirm p[data-v-78e33756]:nth-child(2) {
    -ms-grid-column: 2;
}
.PaymentConfirm p[data-v-78e33756]:nth-child(3) {
    -ms-grid-column: 3;
}
}
@media screen and (-ms-high-contrast: active) and (min-width: 768px), (-ms-high-contrast: none) and (min-width: 768px) {
.PaymentConfirm__card[data-v-78e33756] {
    -ms-grid-columns: 2fr 1fr 1fr;
}
.PaymentConfirm__card label[data-v-78e33756]:nth-child(1) {
    -ms-grid-column: 1;
}
.PaymentConfirm__card label[data-v-78e33756]:nth-child(2) {
    -ms-grid-column: 2;
}
.PaymentConfirm__card label[data-v-78e33756]:nth-child(3) {
    -ms-grid-column: 3;
}
.PaymentConfirm__card p[data-v-78e33756]:nth-child(1) {
    -ms-grid-column: 1;
}
.PaymentConfirm__card p[data-v-78e33756]:nth-child(2) {
    -ms-grid-column: 2;
}
.PaymentConfirm__card p[data-v-78e33756]:nth-child(3) {
    -ms-grid-column: 3;
}
}
@media screen and (-ms-high-contrast: active) and (min-width: 768px), (-ms-high-contrast: none) and (min-width: 768px) {
.PaymentConfirm__card.add-column[data-v-78e33756] {
    grid-gap: 20px;
    -ms-grid-columns: 2fr 1fr 1fr 1fr;
}
.PaymentConfirm__card.add-column label[data-v-78e33756]:nth-child(1) {
    -ms-grid-column: 1;
}
.PaymentConfirm__card.add-column label[data-v-78e33756]:nth-child(2) {
    -ms-grid-column: 2;
}
.PaymentConfirm__card.add-column label[data-v-78e33756]:nth-child(3) {
    -ms-grid-column: 3;
}
.PaymentConfirm__card.add-column label[data-v-78e33756]:nth-child(4) {
    -ms-grid-column: 4;
}
.PaymentConfirm__card.add-column p[data-v-78e33756]:nth-child(1) {
    -ms-grid-column: 1;
}
.PaymentConfirm__card.add-column p[data-v-78e33756]:nth-child(2) {
    -ms-grid-column: 2;
}
.PaymentConfirm__card.add-column p[data-v-78e33756]:nth-child(3) {
    -ms-grid-column: 3;
}
.PaymentConfirm__card.add-column p[data-v-78e33756]:nth-child(4) {
    -ms-grid-column: 4;
}
}
@media screen and (-ms-high-contrast: active) and (min-width: 768px), (-ms-high-contrast: none) and (min-width: 768px) {
.PaymentConfirm__buttons[data-v-78e33756] {
    grid-gap: 20px;
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
}
.PaymentConfirm__buttons button[data-v-78e33756]:nth-child(2) {
    -ms-grid-column: 3;
}
.PaymentConfirm__buttons button[data-v-78e33756]:nth-child(3) {
    -ms-grid-column: 4;
}
}
@media screen and (-ms-high-contrast: active) and (min-width: 768px), (-ms-high-contrast: none) and (min-width: 768px) {
.PaymentResult[data-v-78e33756] {
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 7px;
}
.PaymentResult button[data-v-78e33756]:nth-child(1) {
    -ms-grid-column: 1;
}
.PaymentResult button[data-v-78e33756]:nth-child(2) {
    -ms-grid-column: 4;
}
}
@media screen and (-ms-high-contrast: active) and (min-width: 768px), (-ms-high-contrast: none) and (min-width: 768px) {
.AdminSearch[data-v-78e33756] {
    -ms-grid-columns: 1fr 2fr 1fr;
}
.AdminSearch div[data-v-78e33756]:nth-child(1) {
    -ms-grid-column: 1;
}
.AdminSearch div[data-v-78e33756]:nth-child(2) {
    -ms-grid-column: 2;
}
.AdminSearch div[data-v-78e33756]:nth-child(3) {
    -ms-grid-column: 3;
}
}
@media screen and (-ms-high-contrast: active) and (min-width: 768px), (-ms-high-contrast: none) and (min-width: 768px) {
.AdminSearch.four-column[data-v-78e33756] {
    -ms-grid-columns: 1fr 2fr 1fr 1fr;
}
.AdminSearch.four-column div[data-v-78e33756]:nth-child(1) {
    -ms-grid-column: 1;
}
.AdminSearch.four-column div[data-v-78e33756]:nth-child(2) {
    -ms-grid-column: 2;
}
.AdminSearch.four-column div[data-v-78e33756]:nth-child(3) {
    -ms-grid-column: 3;
}
.AdminSearch.four-column div[data-v-78e33756]:nth-child(4) {
    -ms-grid-column: 4;
}
}
@media screen and (-ms-high-contrast: active) and (min-width: 768px), (-ms-high-contrast: none) and (min-width: 768px) {
.AdminSearch--advanced[data-v-78e33756] {
    -ms-grid-columns: 1fr 1fr 2fr;
}
.AdminSearch--advanced div[data-v-78e33756]:nth-child(1) {
    -ms-grid-column: 1;
}
.AdminSearch--advanced div[data-v-78e33756]:nth-child(2) {
    -ms-grid-column: 2;
}
.AdminSearch--advanced div[data-v-78e33756]:nth-child(3) {
    -ms-grid-column: 3;
}
}
@media screen and (-ms-high-contrast: active) and (min-width: 768px), (-ms-high-contrast: none) and (min-width: 768px) {
.AdminSearch__buttons[data-v-78e33756] {
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
}
.AdminSearch__buttons button[data-v-78e33756]:nth-child(1) {
    -ms-grid-column: 1;
}
.AdminSearch__buttons button[data-v-78e33756]:nth-child(2) {
    -ms-grid-column: 4;
}
}
@media screen and (-ms-high-contrast: active) and (min-width: 768px), (-ms-high-contrast: none) and (min-width: 768px) {
.AdminEditForm__payment[data-v-78e33756] {
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
}
.AdminEditForm__payment div[data-v-78e33756]:nth-child(1),
  .AdminEditForm__payment p[data-v-78e33756]:nth-child(1) {
    -ms-grid-column: 1;
}
.AdminEditForm__payment div[data-v-78e33756]:nth-child(2),
  .AdminEditForm__payment p[data-v-78e33756]:nth-child(2) {
    -ms-grid-column: 2;
}
.AdminEditForm__payment div[data-v-78e33756]:nth-child(3),
  .AdminEditForm__payment p[data-v-78e33756]:nth-child(3) {
    -ms-grid-column: 3;
}
.AdminEditForm__payment div[data-v-78e33756]:nth-child(4),
  .AdminEditForm__payment p[data-v-78e33756]:nth-child(4) {
    -ms-grid-column: 4;
}
.AdminEditForm__payment div[data-v-78e33756]:nth-child(5),
  .AdminEditForm__payment p[data-v-78e33756]:nth-child(5) {
    -ms-grid-column: 5;
}
}
@media screen and (-ms-high-contrast: active) and (min-width: 768px), (-ms-high-contrast: none) and (min-width: 768px) {
.AdminEditForm__total[data-v-78e33756] {
    -ms-grid-columns: 2fr 1fr 1fr;
    grid-gap: 20px;
}
}
@media screen and (-ms-high-contrast: active) and (min-width: 768px), (-ms-high-contrast: none) and (min-width: 768px) {
.AdminEditForm__buttons[data-v-78e33756] {
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
}
.AdminEditForm__buttons button[data-v-78e33756]:nth-child(1) {
    -ms-grid-column: 1;
}
.AdminEditForm__buttons button[data-v-78e33756]:nth-child(2) {
    -ms-grid-column: 4;
}
}
@media screen and (-ms-high-contrast: active) and (min-width: 768px), (-ms-high-contrast: none) and (min-width: 768px) {
.AdminPaymentMethod__address[data-v-78e33756] {
    -ms-grid-columns: 1fr 3fr;
}
.AdminPaymentMethod__address div[data-v-78e33756]:nth-child(1) {
    -ms-grid-column: 1;
}
.AdminPaymentMethod__address div[data-v-78e33756]:nth-child(2) {
    -ms-grid-column: 2;
}
}
@media screen and (-ms-high-contrast: active) and (min-width: 768px), (-ms-high-contrast: none) and (min-width: 768px) {
.AdminPaymentMethod__card[data-v-78e33756] {
    -ms-grid-columns: 2fr 1fr 1fr;
}
.AdminPaymentMethod__card div[data-v-78e33756]:nth-child(1),
  .AdminPaymentMethod__card label[data-v-78e33756]:nth-child(1),
  .AdminPaymentMethod__card p[data-v-78e33756]:nth-child(1) {
    -ms-grid-column: 1;
}
.AdminPaymentMethod__card div[data-v-78e33756]:nth-child(2),
  .AdminPaymentMethod__card label[data-v-78e33756]:nth-child(2),
  .AdminPaymentMethod__card p[data-v-78e33756]:nth-child(2) {
    -ms-grid-column: 2;
}
.AdminPaymentMethod__card div[data-v-78e33756]:nth-child(3),
  .AdminPaymentMethod__card label[data-v-78e33756]:nth-child(3),
  .AdminPaymentMethod__card p[data-v-78e33756]:nth-child(3) {
    -ms-grid-column: 3;
}
}
@media screen and (-ms-high-contrast: active) and (min-width: 768px), (-ms-high-contrast: none) and (min-width: 768px) {
.AdminPaymentMethod__method[data-v-78e33756] {
    -ms-grid-columns: 3fr 1fr;
    grid-gap: 20px;
}
.AdminPaymentMethod__method div[data-v-78e33756]:nth-child(1) {
    -ms-grid-column: 1;
}
.AdminPaymentMethod__method div[data-v-78e33756]:nth-child(2) {
    -ms-grid-column: 2;
    margin-left: 20px;
}
}
@media screen and (-ms-high-contrast: active) and (min-width: 768px), (-ms-high-contrast: none) and (min-width: 768px) {
.AdminSinglePaymentEdit[data-v-78e33756] {
    -ms-grid-columns: 60px calc(25% - 60px) 25% 25% 25%;
}
}
@media screen and (-ms-high-contrast: active) and (min-width: 768px), (-ms-high-contrast: none) and (min-width: 768px) {
.AdminSinglePaymentEdit__reoccur[data-v-78e33756] {
    -ms-grid-columns: 95px 40px 75px 1fr 20px 40px 100px 1fr;
    grid-gap: 5px;
}
}
@media screen and (-ms-high-contrast: active) and (min-width: 768px), (-ms-high-contrast: none) and (min-width: 768px) {
.AdminSuccessfulPage__button[data-v-78e33756] {
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 7px;
}
.AdminSuccessfulPage__button button[data-v-78e33756]:nth-child(1) {
    -ms-grid-column: 1;
}
.AdminSuccessfulPage__button button[data-v-78e33756]:nth-child(2) {
    -ms-grid-column: 4;
}
}
@media screen and (-ms-high-contrast: active) and (min-width: 768px), (-ms-high-contrast: none) and (min-width: 768px) {
.AdminConfirmPage__payment[data-v-78e33756] {
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
}
.AdminConfirmPage__payment div[data-v-78e33756]:nth-child(1),
  .AdminConfirmPage__payment label[data-v-78e33756]:nth-child(1),
  .AdminConfirmPage__payment p[data-v-78e33756]:nth-child(1) {
    -ms-grid-column: 1;
}
.AdminConfirmPage__payment div[data-v-78e33756]:nth-child(2),
  .AdminConfirmPage__payment label[data-v-78e33756]:nth-child(2),
  .AdminConfirmPage__payment p[data-v-78e33756]:nth-child(2) {
    -ms-grid-column: 2;
}
.AdminConfirmPage__payment div[data-v-78e33756]:nth-child(3),
  .AdminConfirmPage__payment label[data-v-78e33756]:nth-child(3),
  .AdminConfirmPage__payment p[data-v-78e33756]:nth-child(3) {
    -ms-grid-column: 3;
}
.AdminConfirmPage__payment div[data-v-78e33756]:nth-child(4),
  .AdminConfirmPage__payment label[data-v-78e33756]:nth-child(4),
  .AdminConfirmPage__payment p[data-v-78e33756]:nth-child(4) {
    -ms-grid-column: 4;
}
.AdminConfirmPage__payment div[data-v-78e33756]:nth-child(5),
  .AdminConfirmPage__payment label[data-v-78e33756]:nth-child(5),
  .AdminConfirmPage__payment p[data-v-78e33756]:nth-child(5) {
    -ms-grid-column: 5;
}
}
@media screen and (-ms-high-contrast: active) and (min-width: 768px), (-ms-high-contrast: none) and (min-width: 768px) {
.AdminConfirmPage__buttons[data-v-78e33756] {
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
}
.AdminConfirmPage__buttons button[data-v-78e33756]:nth-child(1) {
    -ms-grid-column: 1;
}
.AdminConfirmPage__buttons button[data-v-78e33756]:nth-child(2) {
    -ms-grid-column: 4;
}
}
@media screen and (-ms-high-contrast: active) and (min-width: 768px), (-ms-high-contrast: none) and (min-width: 768px) {
.AdminResults[data-v-78e33756] {
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
}
.AdminResults button[data-v-78e33756]:nth-child(2) {
    -ms-grid-column: 3;
}
.AdminResults button[data-v-78e33756]:nth-child(3) {
    -ms-grid-column: 4;
}
}
.StatusContainer[data-v-78e33756] {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(172px, 345px));
  grid-gap: 20px;
  width: 100%;
  justify-content: center;
}
