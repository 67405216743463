
[data-v-432695e8] .green_dot {
  background: #8DC63F;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: inline-block;
}
[data-v-432695e8] .red_dot {
  background: #D9534F;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: inline-block;
}
[data-v-432695e8] .blue_dot {
  background: #2B295E;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: inline-block;
}
[data-v-432695e8] .yellow_dot {
  background: #F8C41C;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: inline-block;
}
[data-v-432695e8] .color-cb {
  color: #70A7DB !important;
}
[data-v-432695e8] .color-sg {
  color: #6A6A6A !important;
}
[data-v-432695e8] .color-rf {
  color: #C9338F !important;
}
.btn-pink[data-v-432695e8] {
  height: 40px;
  width: 100%;
  border: 1px solid #C9338F;
  border-radius: 3px;
  background-color: #C9338F;
  cursor: pointer;
  color: #FFFFFF;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: bold;
  background-size: 24px 24px;
  background-repeat: no-repeat;
  background-position: 6px center;
  padding: 6px 8px 6px 16px;
  box-sizing: border-box;
}
.btn-pink span[data-v-432695e8] {
  text-align: center;
}
.btn-pink.disabled[data-v-432695e8] {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}
