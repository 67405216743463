.circle[data-v-71f9f9fb] {
  text-align: center;
  border-radius: 50%;
  display: table;
  margin: auto;
}
.circle.big[data-v-71f9f9fb] {
  height: 120px;
  width: 120px;
}
.circle.small[data-v-71f9f9fb] {
  height: 40px;
  width: 40px;
}
.circle .pic[data-v-71f9f9fb] {
  background-size: 60px 60px;
  display: table-cell;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
}
.circle .pic.big[data-v-71f9f9fb] {
  background-size: 60px 60px;
}
.circle .pic.small[data-v-71f9f9fb] {
  background-size: 25px 25px;
}
.circle.green[data-v-71f9f9fb] {
  background: #66C400;
}
.circle.green .pic[data-v-71f9f9fb] {
  background-image: url(~assets/img/icon-success@2x.png);
}
.circle.red[data-v-71f9f9fb] {
  background: #D0021B;
}
.circle.red .pic[data-v-71f9f9fb] {
  background-image: url(~assets/img/icon-failed@2x.png);
}
.circle.yellow[data-v-71f9f9fb] {
  background: #F8C41C;
}
.circle.yellow .pic[data-v-71f9f9fb] {
  background-image: url(~assets/img/icon-clock@2x.png);
}
