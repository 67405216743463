.token[data-v-3c73a300] {
  color: #6A6A6A;
  font-family: Montserrat;
  font-size: 10px;
  text-align: center;
  font-weight: 400;
  margin-bottom: 5px;
  margin-top: 0px;
  white-space: normal;
  word-break: break-all;
  box-sizing: border-box;
}
.token p[data-v-3c73a300] {
  padding: 3px 0px;
}
.token b[data-v-3c73a300] {
  font-weight: 700;
}
.token-copy[data-v-3c73a300] {
  width: 12px;
  position: relative;
  top: 2px;
  margin-top: -6px;
  margin-right: 10px;
  left: 5px;
  cursor: pointer;
}
.pl27[data-v-3c73a300] {
  padding-left: 27px;
}
